import React, { FC, useMemo } from 'react';
import * as Styled from './NotificationBubbleStyles';
import { INotification } from 'web-modules-common';

interface IProps {
  notifications: Array<INotification>,
  isRTL: boolean
}


const NotificationBubble: FC<IProps> = ({ notifications, isRTL}) => {
  const unreadNotifications = useMemo(() =>
      notifications.filter(notification => !notification.isRead),
    [notifications],
  );

  return (
    <Styled.NotificationBubble isRTL={isRTL} isRead={unreadNotifications.length === 0}>
      {unreadNotifications.length > 0 ? unreadNotifications.length : ''}
    </Styled.NotificationBubble>
  );
};

export default NotificationBubble;
