
import { authenticationService } from './common/services';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './fonts/fonts.css';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './app/App';
import { Loader } from 'web-modules-common';
import * as serviceWorker from './serviceWorker';
import * as log from 'loglevel';
import { LoginPage } from './common/components/login';
import { configureStore } from './common/redux/store';
import './cacheHandler';

const store = configureStore();


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    log.error(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

const Root = () => {
  const BASIC_PATH = '/1QWeb';
  const loginPath = `${BASIC_PATH}/login`;
  const qweb = `${BASIC_PATH}/home`;
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    authenticationService.isUserLoggedIn().then(isLoggedIn => {
      setIsUserLoggedIn(isLoggedIn);
      setIsLoading(false);
    });
    const sub = authenticationService.userLoggedInSub$.subscribe((isLoggedIn) => {
      setIsUserLoggedIn(isLoggedIn);
    });
    return () => {
      if (sub) sub.unsubscribe();
    };

  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Loader isLoading={isLoading || typeof isUserLoggedIn === "undefined"}>
          <>
            {isUserLoggedIn ? (
              <Switch>
                <Route exact path={qweb} component={App} />
                <Redirect exact from="*" to={qweb} />
              </Switch>
            ) : (
              <Switch>
                <Route exact path={loginPath} component={LoginPage} />
                <Redirect exact from="*" to={loginPath} />
              </Switch>
            )}
          </>
        </Loader>
      </Router>
    </Provider>
  );
};

ReactDOM.render(
  //@ts-ignore
  <ErrorBoundary>
    <Root />
  </ErrorBoundary>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();
