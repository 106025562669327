import { AuthenticationServiceImplementBase } from 'web-modules-common';
import LoginConfigurationsAlternative from '../../../configuration/alternatives/Login';
import { IDMService } from '../IDM/IDM.service';

 export const authenticationServiceFactory = ()  => {
  // @ts-ignore
  // error handling here
  const loginConfig = global.LoginConfigurations? global.LoginConfigurations : LoginConfigurationsAlternative;
  let service: AuthenticationServiceImplementBase;

  switch (loginConfig.authenticationServiceProvider && loginConfig.authenticationServiceProvider.toLowerCase()) {
    case 'idm':
      service =new IDMService();
      break;
    default:
      service = new IDMService();
      break;
  }

  return service;
}
