import {BehaviorSubject} from "rxjs";
import { IAlert, AlertServiceBase } from "web-modules-common";

export class AlertService extends AlertServiceBase {
  alerts$;
  isAlertOpen = false;
  isAlertBlocked = false;
  
  constructor() {
    super();
    this.alerts$ = new BehaviorSubject(null);
  }

  openAlert(alert: IAlert) {
    this.alerts$.next(alert);
  }
}
