import MockModules from '../../../../configuration/Modules.mock.json';

export class UtilitiesPlatform {
  private static utilitiesPlatform: UtilitiesPlatform;
  private modulesConfiguration = { modules : {}};
  private debugMode = false;
  private constructor() { }

  public static getInstance(): UtilitiesPlatform {
    if (!this.utilitiesPlatform) {
      this.utilitiesPlatform = new UtilitiesPlatform();
    }
    return this.utilitiesPlatform;
  }

  get isDebugMode(): boolean {
    return this.debugMode;
  }

  set isDebugMode(v: boolean) {
    this.debugMode = v;
  }

  public setModulesConfiguration(modules: any) {
    this.modulesConfiguration.modules = modules;
  }

  public modulesFileAppMode(mode: string): any {
    if (this.debugMode) {
      return this.modulesConfiguration;
    };

    switch (mode) {
      case 'prod':
        // @ts-ignore
        return global.ModulesConfiguration;
      default:
        return MockModules;
    }
  }
}
