import React, { FC, useEffect, useRef, useState } from 'react';
import * as log from 'loglevel';
import { PopupContent } from 'semantic-ui-react';
import { I18n, SessionStorageKeys,MessageTree, useNotificationsByType, INotification } from 'web-modules-common';
import { ReactComponent as NotificationIcon } from '../../assets/notification.svg';
import * as Styled from '../../headerWidgetStyles';
import NotificationBubble from '../NotificationBubble';
import NotificationContainer from '../NotificationsContainer';
import { alertService,eventBus, notificationService} from '../../../../../services';


interface IProps {

};

const NotificationsTray: FC<IProps> = () => {
  const locale = I18n.getInstance().getLocale();
  const isRTL = I18n.getInstance().isRTL(locale);
  const position = isRTL ? 'top left' : 'top right';
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const notificationRef = useRef(null);
  

  useEffect(() => {
    const savedNotifications = sessionStorage.getItem(SessionStorageKeys.Notifications);
    if (savedNotifications) {
      const parsedNotifications = JSON.parse(savedNotifications);
      setNotifications(parsedNotifications)
    }
  }, []);

  useEffect(() => {
    eventBus.subscribers([
      {
        subscriberId: NotificationsTray.name,
        key: MessageTree.Connectivity.action.initSlidingWindowTimeout,
        callback: (): void => {
          setIsNotificationOpen(false);
        },
      },
      {
        subscriberId: NotificationsTray.name,
        key: MessageTree.Notification.action.trayStoreUpdated,
        callback: (args): void => {
          handletTrayNotificationsUpdate(args)
        },
      },
    ]);
    /* istanbul ignore next */
    return function cleanup(): void {
      handleNotificationClear();
      eventBus.unsubscribe(
        NotificationsTray.name,
        MessageTree.Connectivity.action.initSlidingWindowTimeout
      );
      eventBus.unsubscribe(
        NotificationsTray.name,
        MessageTree.Notification.action.trayStoreUpdated
      );
    };
  }, []);

  useEffect(() => {
    alertService.alerts$.subscribe(alert => {
      if (alert) {
        setIsNotificationOpen(false);
      }
    });
    /* istanbul ignore next */
    return () => {
      if (alertService.alerts$) alertService.alerts$.unsubscribe();
    };
  }, []);

  const sortNotifications = (notificationsList) => {
    return notificationsList.sort((a,b) => {return  new Date(b.notificationTime).getTime() -  new Date(a.notificationTime).getTime()})
  }

  const updateTrayNotificationStore = (trayNotificationsData: Array<INotification>, shouldUpdateNotificationTrayStore: boolean): void => {
    if(shouldUpdateNotificationTrayStore) {
      notificationService.setNotificationTrayStore(trayNotificationsData, false);
    }
    sessionStorage.setItem(SessionStorageKeys.Notifications, JSON.stringify(trayNotificationsData));
    setNotifications(trayNotificationsData);
  }

  const handletTrayNotificationsUpdate = (trayNotificationsData: Array<INotification>): void => {
    const sorted = sortNotifications(trayNotificationsData)
    setNotifications(sortNotifications(sorted));
    updateTrayNotificationStore(sortNotifications(sorted), false);
  }
 

  const handleNotificationOpen = (): void => {
    setIsNotificationOpen(true);
    const readNotifications = notifications.map(notification => {
      notification.isRead = true;
      return notification;
    })
    const sorted = sortNotifications(readNotifications)
    setNotifications(sorted);
    updateTrayNotificationStore(sorted, true);
  };

  const handleNotificationClose = (): void => {
    setIsNotificationOpen(false);
  };

  const handleNotificationClear = (): void => {
    setNotifications([]);
    updateTrayNotificationStore([], true)
    sessionStorage.removeItem(SessionStorageKeys.Notifications);
  };

  const handleMarkAsRead = (id: number): void => {
    const notificationsCopy = [...JSON.parse(sessionStorage.getItem('Notifications'))];
    const notificationToRemove = notificationsCopy.find(n => n.id === id);
    const filteredNotifications = notificationsCopy.filter(n => n.id !== id);
    notificationService.removeNotificationFromTrayStore([notificationToRemove], true);
    sessionStorage.setItem(SessionStorageKeys.Notifications, JSON.stringify(filteredNotifications));
    setNotifications(filteredNotifications);
  };

  log.debug('notifications = ', notifications);

  return (
    <Styled.HeaderNotificationPopUp
      isrtl={isRTL ? 'true' : undefined}
      data-automation={'notification_dialog'}
      on="click"
      trigger={
        <span className="buttonWrapper" ref={notificationRef}>
          <Styled.HeaderButton
            icon={<NotificationIcon />}
            circular
            name="notification"
            id="notification"
            ref={notificationRef}
          />
          <NotificationBubble isRTL={isRTL} notifications={notifications} />
        </span>
      }
      position={position}
      context={notificationRef}
      open={isNotificationOpen}
      onClose={handleNotificationClose}
      onOpen={handleNotificationOpen}
    >
      <PopupContent>
        <NotificationContainer
          notifications={notifications}
          closeNotifications={handleNotificationClose}
          clearNotifications={handleNotificationClear}
          handleMarkAsRead={handleMarkAsRead}
        />
      </PopupContent>
    </Styled.HeaderNotificationPopUp>
  );
};

export default NotificationsTray;
