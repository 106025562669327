import jwt from 'jsonwebtoken';
import { GraphQLServiceBase, PermissionServiceBase, serviceContainer, SessionStorageKeys } from 'web-modules-common';
import * as log from 'loglevel';

export class UserAndConfigurationService {
  private graphQLService;
  private permissionService;
  private userInfoFields: Array<string> = [
    `id`,
    `jobTitle {`,
    `id`,
    `name`,
    `}`,
    `name`,
    `permissions`,
  ];

  private systemConfigurationFields: Array<string> = [
    `allCrOperatorsUserId`,
    `allCrOperatorsJobTitleId`,
    `allowList`,
  ];

  constructor() {
    this.graphQLService = serviceContainer.get(GraphQLServiceBase) as GraphQLServiceBase;
    this.permissionService = serviceContainer.get(PermissionServiceBase) as PermissionServiceBase;
  }

  public getUserInfoAndConfiguration(): Promise<any> {
    const storagedUserData = sessionStorage.getItem(SessionStorageKeys.CurrentUserData);
    let userData = null;
    
    if (storagedUserData !== null) {
      userData = storagedUserData;
    }
    
    if (userData !== null) {
      const accessToken = JSON.parse(userData).AccessToken;
      const decodedAccessToken = jwt.decode(accessToken);
      const userId = decodedAccessToken.user_id;

      sessionStorage.setItem(SessionStorageKeys.CurrentUserID, userId);

      return this.graphQLService
        .invokeQueryForMultipleEntities(
          `GetUserInfo`,
          [`userInfo`, 'systemConfiguration'],
          [`userId`, ''],
          [userId, ''],
          [this.userInfoFields, this.systemConfigurationFields],
        )
        .then(result => {
          if (result.data !== null) {
            result.data.systemConfiguration && this.addSystemConfigurationToSessionStorage(
              result.data.systemConfiguration,
            );

            result.data.userInfo && this.addUserInfoToSessionStorage(result.data.userInfo);

            return Promise.resolve();
          }

          return Promise.reject(new Error('userAndConfiguration.service: no data returned from server'));
        })
        .catch(error => {
          log.error({ error });
          return Promise.reject(new Error(`userAndConfiguration.service: ${error}`));
        });
    }
    return Promise.reject(new Error('userAndConfiguration.service: no userdata on session storage'));
  }

  private addSystemConfigurationToSessionStorage(systemConfiguration): void {
    sessionStorage.setItem(
      SessionStorageKeys.AllowList,
      systemConfiguration.allowList,
    );

    sessionStorage.setItem(
      SessionStorageKeys.AllCROperatorsJobTitleId,
      systemConfiguration.allCrOperatorsJobTitleId,
    );
    sessionStorage.setItem(
      SessionStorageKeys.AllCROperatorsUserId,
      systemConfiguration.allCrOperatorsUserId,
    );
  }

  private addUserInfoToSessionStorage(userInfo): void {
    if (userInfo.jobTitle) {
      sessionStorage.setItem(
        SessionStorageKeys.CurrentUserJobTitleId,
        userInfo.jobTitle.id,
      );
      sessionStorage.setItem(
        SessionStorageKeys.CurrentUserJobTitleName,
        userInfo.jobTitle.name,
      );
    }
    sessionStorage.setItem(
      SessionStorageKeys.CurrentUserName,
      userInfo.name,
    );

    this.permissionService.addPermissions(userInfo.permissions);
  }
}
