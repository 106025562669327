import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { PopupContent } from 'semantic-ui-react';
import { MessageBoxType, NotificationType, Incident, styles, I18n, MessageTree, useNotificationsByType } from 'web-modules-common';
import NotificationsTray from './Notifications/NotificationsTray';
import UserInfoContent from './UserInfoContent';
import * as Styled from "./headerWidgetStyles";
import './styles.scss';
import { ErrorBoundary } from '../../../../modules/infrastructure/ModuleManager';
import { alertService, authenticationService, eventBus, permissionService } from '../../../services';
import * as log from 'loglevel';
import logo from './assets/header-logo.png';
import LoginConfigurationsAlternative from '../../../../configuration/alternatives/Login';
import SessionTimeoutPopupAlternative from '../../../../configuration/alternatives/SessionTimeoutPopup';

interface HeaderWidgetProps {
  invokeLogout?: () => void;
  invokeTokenRefresh?: () => void;
  isRTL: boolean;
}

const HeaderWidget: React.FC<HeaderWidgetProps> = (props: HeaderWidgetProps) => {
  const [isUserInfoOpen, setIsUserInfoOpen]                   = useState(false);
  const [isDisconnected, setIsDisconnected]                   = useState(false);
  const [slidingWindowPopupTimer, setSlidingWindowPopupTimer] = useState(false);
  const incidentsCreated                                      = useNotificationsByType(NotificationType.IncidentCreated, onIncidentCreatedNotification);
  //@ts-ignore
  const sessionTimeoutPopup = global.SessionTimeoutPopup ? global.SessionTimeoutPopup : SessionTimeoutPopupAlternative;

  const intl = useIntl();
  const locale = I18n.getInstance().getLocale();
  const isRTL = I18n.getInstance().isRTL(locale);
  const userInfoRef = useRef(null);
  const userInfoIcon = <styles.image.UserInfo id="user-info"/>;
  const userInfoButton = (
    <Styled.HeaderButton
      icon={userInfoIcon}
      circular
      name="userInfo"
      id="userInfo"
      ref={userInfoRef}
    />
  );
  const position = props.isRTL ? 'top left' : 'top right';
  let timeoutID = null;

  useEffect(() => {
    playNewIncidentAlert(true);
  }, []);

  useEffect(() => {
    eventBus.subscribers(registerToEvents);
    alertService.alerts$.subscribe(alert => {
      if (alert) {
        setIsUserInfoOpen(false);
      }
    });
    /* istanbul ignore next */
    return () => {
      eventBus.unsubscribe(HeaderWidget.name, MessageTree.Global.action.showGraphQLError);
      eventBus.unsubscribe(HeaderWidget.name, MessageTree.SignalR.action.signalRDisconnected);
      eventBus.unsubscribe(HeaderWidget.name, MessageTree.Connectivity.action.initSlidingWindowTimeout);
      eventBus.unsubscribe(HeaderWidget.name, MessageTree.Connectivity.action.tokenIsRefreshed);
      eventBus.unsubscribe(HeaderWidget.name, MessageTree.SignalR.action.signalRReconnected);

      if (alertService.alerts$) alertService.alerts$.unsubscribe();
    };
  }, []);

  useEffect(() => {

    if(slidingWindowPopupTimer){
      const timer = sessionTimeoutPopup && sessionTimeoutPopup.messageBoxCloseTimeout || 60000;
      timeoutID = setTimeout(onLogout, timer);
    }else {
      clearTimeout(timeoutID);
      timeoutID = null;
    }
    return () => {
      clearTimeout(timeoutID);
      timeoutID = null;
    }

  }, [slidingWindowPopupTimer])

  function onIncidentCreatedNotification(data: any): void {
    if (data) {
        const incidentFromNotification = data.ExtendedData.Incident;
        const incidentToCheck = new Incident();

        incidentToCheck.assigneeId = incidentFromNotification.AssigneeId;
        incidentToCheck.assigneeName = incidentFromNotification.AssigneeName;

        incidentToCheck.stakeholders = {
          users: incidentFromNotification.StakeHoldersUserIds,
          jobTitles: incidentFromNotification.StakeHoldersJobTitlesIds
        };

        incidentToCheck.collaborators = {
          users: incidentFromNotification.CollaboratorsUserIds,
          jobTitles: incidentFromNotification.CollaboratorsJobTitlesIds
        };

        const userHasPermissions = permissionService.checkIfUserHasPermissions(incidentToCheck) ;

        if(userHasPermissions) {
          playNewIncidentAlert(false);
        }
      }
  }

  const playNewIncidentAlert = (muted: boolean) => {
    try {
      // @ts-ignore
      const loginConfig = global.LoginConfigurations ? global.LoginConfigurations : LoginConfigurationsAlternative;
      const src = `${loginConfig.endPoints.QWebClient}/sounds/newIncident.wav#${new Date().getTime()}`;
      const audio = new Audio(src);
      audio.muted = muted;
      audio.play().then().catch(e => log.warn(e));
    } catch (e) {
      log.warn(e);
    }
  }

  const resetAlerts = () => {
    setSlidingWindowPopupTimer(false);
    alertService.isAlertOpen = false;
    if(alertService.isAlertBlocked){
      alertService.isAlertBlocked = false;
      setTimeout(() => authenticationService.initValidateTimeout(false), 1000 )
    }
  }

  const onCloseMessageBox = (): void => {
    setIsDisconnected(false);
    resetAlerts();
  };

  const handleOpen = (): void => {
    setIsUserInfoOpen(true);
  };

  const handleClose = (): void => {
    setIsUserInfoOpen(false);
  };

  const onLogout = (): void => {
    setIsUserInfoOpen(false);
    setSlidingWindowPopupTimer(false);
    props.invokeLogout();
    alertService.isAlertOpen = false;
    alertService.isAlertBlocked = false;
  };

  const getErrorMessage = (messageId: string): string => {
    const defaultMessage = intl.formatMessage({ id: 'ErrorOccurred' });
    if (typeof messageId === 'string' && messageId.startsWith('[?:') && messageId.endsWith(']')) {
      messageId = messageId.slice(3);
      messageId = messageId.slice(0, -1);
    }
    return intl.formatMessage({ id: messageId, defaultMessage: defaultMessage });
  };

  const getSlidingContentText = (): string => {
      return intl.formatMessage({ id: 'LogOutMessageBox' });
  }

  function handleCancel(){
    setIsUserInfoOpen(false);
    alertService.isAlertOpen = false;
    setSlidingWindowPopupTimer(false);
    props.invokeTokenRefresh && props.invokeTokenRefresh();
  }

  const registerToEvents = [
    {
      subscriberId: HeaderWidget.name,
      key: MessageTree.Global.action.showGraphQLError,
      callback: (errorMessageId): void => {
        if (!isDisconnected) {
          const errorMessage = getErrorMessage(errorMessageId);
          alertService.openAlert({
            type: MessageBoxType.Error,
            contentText: errorMessage,
            confirmText: intl.formatMessage({ id: 'errorMessageBox.OK' }),
            closeText:intl.formatMessage({ id: 'errorMessageBox.Cancel'}),
            onConfirm: () => onCloseMessageBox()
          });
          alertService.isAlertOpen = true;
        }
      },
    },
    {
      subscriberId: HeaderWidget.name,
      key: MessageTree.SignalR.action.signalRDisconnected,
      callback: (errorMessageId): void => {
        if (!isDisconnected){
          setIsDisconnected(true);
          const errorMessage = getErrorMessage(errorMessageId);
          alertService.openAlert({
            type: MessageBoxType.Error,
            contentText: errorMessage,
            confirmText: intl.formatMessage({ id: 'errorMessageBox.OK' }),
            closeText:intl.formatMessage({ id: 'errorMessageBox.Cancel'}),
            onConfirm: () => onCloseMessageBox()
          });
          alertService.isAlertOpen = true;
        }
      },
    },
    {
      subscriberId: HeaderWidget.name,
      key: MessageTree.Connectivity.action.initSlidingWindowTimeout,
      callback: (): void => {
        setIsUserInfoOpen(false);
        if(alertService.isAlertOpen){
          log.info(`Sliding window message is blocked`);
          alertService.isAlertBlocked = true;
        } else{
          log.info(`Sliding window message is open`);
          alertService.openAlert({
            type: MessageBoxType.Warning,
            contentText: getSlidingContentText(),
            confirmText: intl.formatMessage({ id: 'errorMessageBox.OK' }),
            closeText:intl.formatMessage({ id: 'errorMessageBox.Cancel'}),
            onConfirm: () => onLogout(),
            onClose: () => handleCancel()
          })

          setSlidingWindowPopupTimer(true);
        }
      },
    },
    {
      subscriberId: HeaderWidget.name,
      key: MessageTree.SignalR.action.signalRReconnected,
      callback: (): void => {
        if(slidingWindowPopupTimer){
          setSlidingWindowPopupTimer(false);
        }
      }
    },
    {
      subscriberId: HeaderWidget.name,
      key: MessageTree.Connectivity.action.tokenIsRefreshed,
      callback: (): void => {
        if(slidingWindowPopupTimer){
          log.info('Authenticaiton Service: Closing sliding window popup because token was refreshed');
          setSlidingWindowPopupTimer(false);
          alertService.isAlertBlocked = false;
          alertService.isAlertOpen = false;
        }

      }
    },
  ];
  return (
    <ErrorBoundary>
      <Styled.Header>
        <div className={'logo-wrapper'}>
           <img className={'icon'} src={logo} alt="Qognify logo" />
        </div>
        <div className="w-100 d-flex fd-r jc-fe ai-c">
          <NotificationsTray />
          <Styled.HeaderUserPopUp
            isrtl={isRTL ? 'true' : undefined}
            on="click"
            trigger={
              <Styled.ButtonWrapper ref={userInfoRef}>
                {userInfoButton}
              </Styled.ButtonWrapper>
            }
            position={position}
            context={userInfoRef}
            open={isUserInfoOpen}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            <PopupContent className="popUpContent">
              <UserInfoContent
                invokeLogout={onLogout}
                isRTL={isRTL}
              />
            </PopupContent>
          </Styled.HeaderUserPopUp>
        </div>
      </Styled.Header>
    </ErrorBoundary>
  );
};

export default HeaderWidget;
