import styled from 'styled-components';
import {styles} from 'web-modules-common';

const getItemMargin = (isRtl: boolean): string => {
  return isRtl
    ? '10px 20px 10px 0'
    : '10px 0 10px 20px';
};

const getItemBorderRadius = (isRtl: boolean): string => {
  return isRtl
    ? `0 ${styles.borderRadius.PRIMARY} ${styles.borderRadius.PRIMARY} 0`
    : `${styles.borderRadius.PRIMARY} 0 0 ${styles.borderRadius.PRIMARY}`;
};
const StyledSidebarWrapper = styled.div`
  width: ${styles.sizes.SIDEBAR};
  display: flex;
  flex-direction: column;
`;

const StyledSidebarItem = styled.button<{
  active?: boolean;
  isRtl?: boolean;
}>`
  margin: ${(props): string =>
    props.isRtl ? getItemMargin(true) : getItemMargin(false)};
  padding: ${(props): string =>
    props.isRtl ? '14px 0 14px 14px' : '14px 14px 14px 0'};
  cursor: pointer;
  border-radius: ${(props): string =>
    props.isRtl
      ? getItemBorderRadius(true)
      : getItemBorderRadius(false)};
  background-color: ${(props): string =>
    props.active ? styles.color.brand.PRIMARY : 'transparent'};
  color: ${styles.color.shade.WHITE};
  transition: ${styles.transition.PRIMARY};
  text-transform: capitalize;
  box-shadow: ${(props): string | false | undefined =>
    props.active && styles.shadow.sidebar.PRIMARY};
    width: 125px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  &:hover {
    background-color: ${styles.color.brand.PRIMARY_HOVER};
  }
  &:focus {
    outline: none;
  }
  opacity: 1;
  pointer-events: auto;
  border: none;
`;

const StyledIconWrapper = styled.div<{
  isRTL?: boolean;
  iconHeight?: string;
  iconWidth?: string;
}>`
  height: ${ props =>  props.iconHeight ? props.iconHeight : '32px' };
  width: ${ props =>  props.iconWidth ? props.iconWidth : 'auto' };
  display: block;
  margin: 0 auto;
  ${(props): string => (props.isRTL ? 'transform: scaleX(-1)' : '')};
`;

const StyledText = styled.div`
  margin: 4px 0 0 0;
  text-align: center;
  font-size: ${styles.typographyScale.TYPE16};
  font-weight: ${styles.fontWeight.SEMIBOLD};
`;

export {
  StyledSidebarItem,
  StyledText,
  StyledSidebarWrapper,
  StyledIconWrapper
};
