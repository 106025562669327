/* istanbul ignore file */
// class is module raw data
import { LazyExoticComponent, ComponentType } from 'react';
import {
  ModuleCategory,
  ModulePlacement,
  ModuleVisibility,
  ModuleStatus,
  ModuleType,
} from 'web-modules-common';

export class BaseModule {
  public id: string;

  public componentName: string;

  public serviceName?: string;

  public serviceInstance: any; // need to define a abstract class (base width service class)

  public JSXComponent: LazyExoticComponent<ComponentType<any>>;

  public config: string;

  public certificate: string;

  public endpoints: Array<string>;

  public moduleID: string;

  public moduleType: ModuleType;

  public status?: ModuleStatus;

  public category?: ModuleCategory;

  public placement?: ModulePlacement;

  public visibility?: ModuleVisibility;

  public name?: string;

  public Icon?: LazyExoticComponent<ComponentType<any>>;

  public iconName?: string;

  public iconWidth?: string;

  public iconHeight?: string;

  public notifications?: Array<any>;

  public debug?: boolean;
}
