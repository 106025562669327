import { MessageTree, ModuleNavigationServiceBase, ModuleType, SessionStorageKeys, IncidentModuleArgs, MapModuleArgs } from "web-modules-common";
import { ModuleManager } from "../../../modules/infrastructure/ModuleManager";
import {eventBus} from '..';

export class ModuleNavigationService extends ModuleNavigationServiceBase {
  //istanbul ignore next
  navigateTo(moduleType: ModuleType, args?: IncidentModuleArgs | MapModuleArgs) {
    const moduleID = ModuleManager.getInstance().getModuleIDByModuleType(moduleType);
    if (moduleID) {
      eventBus.publish(
        MessageTree.modules.action.globalActiveModule,
        {
          activeModuleId: moduleID
        }
      )

       eventBus.publish(
          MessageTree.modules.action.getDataFromPreviousModule,
          {
            moduleID: moduleID,
            args: args
          }
        )
    }
  }
}
