import { NotificationService } from './notification/notification.service';
import { UserAndConfigurationService } from './userAndConfiguration/userAndConfiguration.service';
import { GraphQLService } from './graphQL/graphQL.service';
import { AzureService } from './Azure/azure.service';
import { AlertService } from './Alert/alert.service';
import { AuthenticationService } from './Authentication/authentication.service';
import { 
  AuthenticationServiceBase, 
  NotificationServiceBase, 
  GraphQLServiceBase, 
  serviceContainer, 
  EventBusBase, 
  AlertServiceBase,
  PermissionServiceBase,
  ModuleNavigationServiceBase
} from 'web-modules-common';
import { EventBus } from '../../modules/infrastructure/EventBus';
import { PermissionService } from './permission/permission.service';
import { ModuleNavigationService } from './moduleNavigation/moduleNavigation.service';

serviceContainer.init([
  {service: EventBus, abstract: EventBusBase},
  {service: AuthenticationService, abstract: AuthenticationServiceBase},
  {service: NotificationService, abstract: NotificationServiceBase},
  {service: GraphQLService, abstract: GraphQLServiceBase},
  {service: AlertService, abstract: AlertServiceBase},
  {service: PermissionService, abstract: PermissionServiceBase},
  {service: ModuleNavigationService, abstract: ModuleNavigationServiceBase}
])

// INIT ALL SERVICES HERE, DO NOT INIT SERVICE IN ANY OTHER PLACE, KEEP IT SINGLE TONE
const eventBus                    = serviceContainer.get(EventBusBase) as EventBusBase;
const notificationService         = serviceContainer.get(NotificationServiceBase) as NotificationServiceBase;
const graphQLService              = serviceContainer.get(GraphQLServiceBase) as GraphQLServiceBase;
const userAndConfigurationService = new UserAndConfigurationService();
const authenticationService       = serviceContainer.get(AuthenticationServiceBase) as AuthenticationServiceBase;
const azureService                = new AzureService();
const alertService                = serviceContainer.get(AlertServiceBase) as AlertServiceBase;
const permissionService           = serviceContainer.get(PermissionServiceBase) as PermissionServiceBase;
const moduleNavigationService     = serviceContainer.get(ModuleNavigationServiceBase) as ModuleNavigationServiceBase

export {
  userAndConfigurationService,
  notificationService,
  graphQLService,
  azureService,
  alertService,
  serviceContainer,
  authenticationService,
  eventBus,
  permissionService,
  moduleNavigationService
};
