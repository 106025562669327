import styled from 'styled-components';
import {styles} from 'web-modules-common';
import { Button, Popup } from 'semantic-ui-react';

const Header = styled.div`
  height: ${styles.sizes.HEADER};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 5px;
`;

const HeaderNotificationPopUp = styled(Popup as any)<{ isrtl: boolean }>`
  box-shadow: 0 0 12px rgba(38, 40, 58, 0.1) !important;
  border-radius: 4px !important;
  width: 386px;
  max-height: 557px;
  max-width: 386px !important;
  direction: ${(props): string => (props.isrtl ? 'rtl' : 'ltr')};
  left: ${(props): string => (props.isrtl ? '-10px' : '10px')} !important;
  padding: 14px 20px 21px;
`;
const HeaderUserPopUp = styled(Popup as any)<{ isrtl: boolean }>`
  box-shadow: 0 0 12px rgba(38, 40, 58, 0.1) !important;
  border-radius: 4px !important;
  width: 297px;
  height: 225px;
  max-width: 297px !important;
  direction: ${(props): string => (props.isrtl ? 'rtl' : 'ltr')};
  left: ${(props): string => (props.isrtl ? '-10px' : '10px')} !important;
`;

const HeaderUserPopUpContent = styled(Popup.Content)`
  margin: 14px 10px 22px 10px;
`;
const HeaderNotificationPopUpContent = styled(Popup.Content)`

`;

const HeaderButton = styled(Button as any)`
  background-color: rgba(255, 255, 255, 0.1) !important;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 0 !important;
  margin: 0 10px !important;
  &:hover {
    color: transparent !important;
    background-color: ${styles.color.brand.PRIMARY_HOVER}!important;
  }
  border-radius:100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 103px;
  height: 29px;
  margin: 20px 26px 23px 26px;
`;

const HeaderButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const HeaderBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 20px 0 20px;
`;

const UserInfoContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
`;

const UserInfoHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const UserInfoHeaderLabel = styled.label`
  font-family: ${styles.font.PRIMARY};
  font-weight: ${styles.fontWeight.SEMIBOLD};
  font-size: ${styles.typographyScale.TYPE16};
  line-height: 24px;
  color: ${styles.color.shade.DARK};
  flex: 40%;
`;

const UserInfoImage = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const ButtonWrapper = styled.span`
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  margin-left: 30px;
  position: relative;
  border-radius:100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoutButton = styled.button`
  font-family: ${styles.font.PRIMARY};
  font-weight: ${styles.fontWeight.SEMIBOLD};
  font-size: ${styles.typographyScale.TYPE16};
  line-height: 22px;
  color: ${styles.color.shade.DARK};
  background-color: ${styles.color.shade.TRANSPARENT};
  flex: 14%;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
`;

const LogoutImage = styled.div<{ isrtl: boolean }>`
  transform: ${(props): string =>
    props.isrtl ? 'rotate(180deg)' : 'rotate(0deg)'};
  width: 16px;
  height: 16px;
  vertical-align: middle;
`;

const InitialsComponentWrapper = styled.span`
  display: flex;
  justify-content: center;
`;

const UserInfoFieldValueWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 10px;
`;

const FieldsWrapper = styled.span`
  display: flex;
  flex-direction: column;
  margin: 0 5px;
`;


const FieldLabel = styled.label`
  font-family: ${styles.font.PRIMARY};
  font-weight: ${styles.fontWeight.NORMAL};
  font-size: ${styles.typographyScale.TYPE14};
  line-height: 21px;
  color: ${styles.color.shade.GRAY};
  margin-top: 15px;
`;

const ValueLabel = styled.label`
  font-family: ${styles.font.PRIMARY};
  font-weight: ${styles.fontWeight.NORMAL};
  font-size: ${styles.typographyScale.TYPE14};
  line-height: 21px;
  color: ${styles.color.shade.DARK};
  margin-top: 15px;
  max-width: 146px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export {
  Header,
  HeaderButton,
  Logo,
  HeaderButtonsWrapper,
  HeaderBody,
  HeaderUserPopUp,
  HeaderNotificationPopUp,
  HeaderNotificationPopUpContent,
  UserInfoHeaderWrapper,
  UserInfoImage,
  ButtonWrapper,
  UserInfoContentWrapper,
  UserInfoHeaderLabel,
  LogoutButton,
  HeaderUserPopUpContent,
  LogoutImage,
  InitialsComponentWrapper,
  UserInfoFieldValueWrapper,
  FieldsWrapper,
  FieldLabel,
  ValueLabel
};
