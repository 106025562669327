import * as log from 'loglevel';
import React, { FC, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import HeaderWidget from '../common/components/headerWidget/ui/headerWidget';
import WorkingArea from '../common/components/workingArea/ui/workingArea';
import AppConfig from '../configuration/AppConfig.json';
import Sidebar from '../common/components/sidebar/ui/sidebar';
import {
  authenticationService,
  notificationService,
  userAndConfigurationService,
  eventBus,
  permissionService
} from '../common/services';

import { StyledApp, StyledAppBody, StyledAppHeader, WorkingAreaWrapper } from './styledApp';
import { BaseModule } from '../modules/infrastructure/BaseModule';
import { ModuleManager } from '../modules/infrastructure/ModuleManager';
import {
  Loader,
  SessionStorageKeys,
  GraphQLServiceBase,
  NotificationServiceBase,
  I18n,
  MessageTree,
  GlobalAlert
} from 'web-modules-common';
//@ts-ignore
const eventsTypesFromWorker = {
  updateAllRequests: `updateAllRequests`,
  logoutFromClient: `logoutFromClient`,
};
let appInitTime = new Date();
const localeKey = `locale`;

const App: FC = () => {
  log.enableAll();
  const I18nInstance = I18n.getInstance();
  const locale = I18nInstance.getLocale();
  const { name } = App;
  const [modulesMap, updateModulesMap] = useState(new Map<string, BaseModule>());
  const [readyForRendering, setReadyForRendering] = useState(false);
  const [asyncOperationsCompleted, setAsyncOperationsCompleted] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [hasErrorOnLoad, setHasErrorOnLoad] = useState(false);

  useEffect(() => {
    // init services in container service
    const moduleManager = ModuleManager.getInstance();
    eventBus.subscribers(registerToWebAPIErrorEvents);

    if(sessionStorage.getItem(SessionStorageKeys.ModulesAlreadyLoaded) === null){
      moduleManager.addModules().then(()=>{setReadyForRendering(true)});
    } else {
      sessionStorage.removeItem(SessionStorageKeys.ModulesAlreadyLoaded);
    }

    asyncOperationsBeforeRendering().then();

    /* istanbul ignore next */
    const intervalRef = setInterval(() => {
      const currentDateInSystem = new Date();
      if (currentDateInSystem.getDate() !== appInitTime.getDate()) {
        appInitTime = currentDateInSystem;
        eventBus.publish(
          MessageTree.DateTime.action.dateUpdated,
          currentDateInSystem,
        );
      }
    }, 60000);

    return (): void => {
      clearInterval(intervalRef);
    };
  }, []);

  const asyncOperationsBeforeRendering = () => {
    return new Promise<void>((resolve , reject) => {
      Promise.all([
        notificationService.init(),
        userAndConfigurationService.getUserInfoAndConfiguration(),
        permissionService.getActions()
      ]).then(([notification]) => {
        eventBus.unsubscribe(name, MessageTree.SignalR.action.signalRDisconnected);
        setHasErrorOnLoad(false);
        setAsyncOperationsCompleted(true);
        resolve();
      }).catch(error => {
        localStorage.setItem(SessionStorageKeys.HasLoadError, 'true');
        setHasErrorOnLoad(true);
        invokeLogoutRequest();
      });
    })
  }

  const registerToWebAPIErrorEvents = [
    {
      subscriberId: name,
      key: MessageTree.SignalR.action.signalRDisconnected,
      callback: (errorMessageId): void => {
        /* istanbul ignore next */
        localStorage.setItem(SessionStorageKeys.HasLoadError, 'true');
        /* istanbul ignore next */
        setHasErrorOnLoad(true);
        /* istanbul ignore next */
        invokeLogoutRequest();
      }
    }
  ];

  const isRTL = I18nInstance.isRTL(locale);

  // get all modules from json file

  /* istanbul ignore next */
  const invokeLogoutPath = (): void => {
    sessionStorage.clear();
    notificationService.disconnect(false);
    setRedirectToLogin(true);
  };

  /* istanbul ignore next */
  const invokeLogoutRequest = (): void => {
    authenticationService.logout().then(() => {
      invokeLogoutPath();
    });
  };

  /* istanbul ignore next */
  const invokeTokenRefresh = (): void => {
    authenticationService.getValidAccessToken();
  };

  const registerToModulesEvents = [
    {
      subscriberId: name,
      key: MessageTree.modules.action.addModule,
      callback: (module: BaseModule): void => {
        /* istanbul ignore next */
        if (modulesMap.get(module.moduleID) === undefined){
          modulesMap.set(module.moduleID, module);
        }
      },
    },
    {
      subscriberId: name,
      key: MessageTree.modules.action.unLoadModule,
      callback: (modules: Map<string, BaseModule>): void => {
        /* istanbul ignore next */
        updateModulesMap(modules);
      },
    },
  ];

  const registerToIDMEvents = [
    {
      subscriberId: name,
      key: MessageTree.Connectivity.action.logout,
      callback: invokeLogoutPath,
    },
    {
      subscriberId: name,
      key: MessageTree.Connectivity.action.failedLogout,
      callback: invokeLogoutPath,
    },
    {
      subscriberId: name,
      key:
      MessageTree.Connectivity.action.failedRefreshAccessToken,
      callback: invokeLogoutPath,
    },
  ];

  eventBus.subscribers(registerToModulesEvents);
  eventBus.subscribers(registerToIDMEvents);
  eventBus.subscribe(App.name,'ReadyForRendering',(isLoaded: boolean)=>{setReadyForRendering(isLoaded)});

  if (localStorage.getItem(localeKey) === null){
    localStorage.setItem(localeKey, AppConfig.defaultLanguageKey);
  }

  if (redirectToLogin && !hasErrorOnLoad) {
    window.location.replace('/1QWeb/login');
  }

  return (
    <Loader isLoading={!(readyForRendering && asyncOperationsCompleted)}>
      <IntlProvider
        locale={locale}
        key={locale}
        messages={I18nInstance.getMessages(locale)}
        textComponent="span"
      >
        <StyledApp isRTL={isRTL}>
          <StyledAppHeader id="topPanel" isRTL={isRTL}>
            <HeaderWidget
              invokeLogout={invokeLogoutRequest}
              isRTL={isRTL}
              invokeTokenRefresh={invokeTokenRefresh}
            />
          </StyledAppHeader>
          <StyledAppBody id="main">
            <Sidebar/>
            <WorkingAreaWrapper>
              <WorkingArea modules={modulesMap}/>
            </WorkingAreaWrapper>
          </StyledAppBody>
          <GlobalAlert/>
        </StyledApp>
      </IntlProvider>
    </Loader>
  );
};

export default App;
