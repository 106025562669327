import React, { FC } from 'react';
import NotificationItem from '../NotificationItem';
import './styles.scss';
import { INotification } from 'web-modules-common';
import { useIntl } from 'react-intl';

interface IProps {
  notifications: Array<INotification>,
  closeNotifications: () => void,
  clearNotifications: () => void,
  handleMarkAsRead: (id: number) => void
}

const NotificationsContainer: FC<IProps> = ({ notifications, closeNotifications, clearNotifications, handleMarkAsRead }) => {
  const intl = useIntl();

  return (
    <>
      <div className="d-flex jc-sb ai-c">
        <div className="notificationCount">
          {intl.formatMessage({ id: 'notifications.notificationsTitle' })} ({notifications.length})
        </div>
        <button className="markAllAsRead" data-testid="markAllAsRead" type="button" onClick={clearNotifications}>
          {intl.formatMessage({ id: 'notifications.markAllAsRead' })}
        </button>
      </div>
      <div className={`notificationContainer ${notifications.length > 0 ? '' : 'flex'}`}>
        {
          notifications.length > 0
            ? notifications.map((notification) =>
              // @ts-ignore
              <NotificationItem
                {...notification}
                closeNotifications={closeNotifications}
                handleMarkAsRead={handleMarkAsRead}
                key={`${notification.id}-${notification.notificationTime}`}
              />,
            )
            : intl.formatMessage({ id: 'notifications.noNotifications' })
        }
      </div>
    </>
  );
};

export default NotificationsContainer;
