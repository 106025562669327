global.LoginConfigurationsAlternative = {
  loginProvider: "",
  authenticationServiceProvider: "idm",
  clientSecret: 'QAppsSecret',
  providers: {
    AZURE: {
      key: 'azure',
      clientId: 'QAppsAzureConsumer'
    },
    ACTIVE_DIRECTORY: {
      key: 'active directory',
      clientId: 'QAppsAdConsumer'
    },
    USERNAME_PASSWORD: {
      key: "",
      clientId: 'QAppsConsumer'
    }
  },
  // azure, active directory, "" = username password
  endPoints: {
    AuthenticationServerUrl: ``,
    QWebClient: `${window.location.protocol}//${window.location.host}/1QWeb`,
    WebApi: {
      ServerUrl: ``,
      ODATAPrefix: 'odata',
      GraphQLPrefix: 'api/graphql',
    },
  },
  Authentication: {
    loginTryouts: 5,
    RefreshTokenTimeBufferInSeconds: 20,
  },
  Azure: {
    clientID: '43931dc0-d4da-4533-807d-ff39d88f8649',
    tenantID: 'c52adb49-d4df-4fd6-9b77-1443503a1055',
    authorityUrl: 'https://login.microsoftonline.com',
    redirectUri: `${window.location.protocol}//${window.location.host}/1QWeb`,
  },
  signalR: {
    transportConnectTimeout: 20000,
  },
  QMobile: {
    LocationRefreshRate: 5,
    LocationEnabled: false,
    LocationBackground: false,
    LocationSensitivity: "GPS"
  }
};

module.exports = global.LoginConfigurationsAlternative;
