import axios, { AxiosResponse } from 'axios';
import * as log from 'loglevel';
import {
  HTTPResponseCode,
  UtilitiesGlobal,
  ISecurityToken,
  Response,
  AuthenticationServiceImplementBase
} from 'web-modules-common';
import LoginConfigurationsAlternative from '../../../configuration/alternatives/Login';

// Authentication Service
export class IDMService implements AuthenticationServiceImplementBase {

  //@ts-ignore
  private utilitiesGlobal: UtilitiesGlobal;
  //@ts-ignore
  private loginConfig: any;


  constructor() {
    //@ts-ignore
    this.loginConfig = global.LoginConfigurations? global.LoginConfigurations : LoginConfigurationsAlternative;
    //@ts-ignore
    this.utilitiesGlobal = UtilitiesGlobal.getInstance();
  }

  // @ts-ignore
  async login(userName: string, password: string): Promise<Response> {
    let response;
    let errorData;
    try {
      const credentials = this.getCredentials();
      const body = IDMService.createLoginRequestBody(userName, password);
      response = await this.sendIdmLoginRequest(body, { Authorization: `Basic ${credentials}` });
    } catch (error) {
      errorData = error;
    }

    return this.createResponse(response, errorData);
  }

  async loginWithExternalToken(token: string): Promise<Response> {
    let response;
    let errorData;

    try {
      response = await this.sendIdmLoginRequest(this.createLoginAzureRequestBody(token));
    } catch (error) {
      /* istanbul ignore next */
      errorData = error;
    }

    return this.createResponse(response, errorData);
  }

  // eslint-disable-next-line consistent-return
  async refreshToken(securityToken: ISecurityToken) {
    try {
      // eslint-disable-next-line no-throw-literal
      if (!securityToken) throw new Error('Security token is missing');
      const credentials = this.getCredentials();
      const { data } = await axios.post(`${this.loginConfig.endPoints.AuthenticationServerUrl}/connect/token`,
        this.createRefreshTokenRequestBody(`${securityToken.RefreshToken}`), {
          headers: {
            Authorization: `Basic ${credentials}`,
            'Content-Type': `application/x-www-form-urlencoded`,
          }
        });

      return data;
    }
    catch (error) {
      log.error(`IDMService, refreshToken, error: ${error}`);
      return error;
    }
  }

  async logout(accessToken?: string, refreshToken?: string): Promise<Response> {
    let response: AxiosResponse<any>;
    let errorData: any;

    try {
      // @ts-ignore
      if (global.LoginConfigurations.loginProvider.toLowerCase() === global.LoginConfigurations.providers.AZURE.key.toLowerCase()) {
        this.clearCookies();
      } 
      response = await axios.post(`${this.loginConfig.endPoints.AuthenticationServerUrl}/Qognify/Logout`,
        IDMService.createLogoutRequestBody(accessToken, refreshToken),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      

    } catch (error) {
      log.error(error);
      errorData = error;
    }

    return this.createResponse(response, errorData);
  }

  async sendIdmLoginRequest(body, headers = {}) {
    const url = `${this.loginConfig.endPoints.AuthenticationServerUrl}/connect/token`;
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...headers
      },
    };
    return await axios.post(url, body, config)
  }

  public getCredentials(): string {
    return this.utilitiesGlobal.generateBase64(
      this.getClientId(),
      this.loginConfig.clientSecret,
    );
  }

  /* istanbul ignore next */
  private clearCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }

  /* istanbul ignore next */
  private getClientId() {
    switch (this.loginConfig.loginProvider.toLowerCase()) {
      case this.loginConfig.providers.AZURE.key.toLowerCase():
        return this.loginConfig.providers.AZURE.clientId;

      case this.loginConfig.providers.ACTIVE_DIRECTORY.key.toLowerCase():
        return this.loginConfig.providers.ACTIVE_DIRECTORY.clientId;

      default:
        return this.loginConfig.providers.USERNAME_PASSWORD.clientId;
    }
  }

  private createLoginAzureRequestBody(token: string): string {
    return `grant_type=AzureWebAPIGrant&scope=WebAPIConsumer+openid+profile+email+roles+offline_access&client_id=${encodeURIComponent(this.loginConfig.providers.AZURE.clientId)}&client_secret=${encodeURIComponent(this.loginConfig.clientSecret)}&win_token=${encodeURIComponent(token)}`;
  }

  private static createLoginRequestBody(username: string, password: string): string {
    return `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&scope=WebAPIConsumer+openid+roles+offline_access+profile`;
  }

  private static createLogoutRequestBody(AccessToken: string, refreshToken: string): any {
    return `AccessToken=${encodeURIComponent(AccessToken)}&RefreshToken=${encodeURIComponent(refreshToken)}`;
  }

  private createRefreshTokenRequestBody(refreshToken: string): string {
    return `grant_type=refresh_token&refresh_token=${encodeURIComponent(refreshToken)}`;
  }

  private createResponse(serverResponse: any, errorData: any): Response {
    const responseData = serverResponse ? serverResponse.data : serverResponse
    const response: Response = {
      data: responseData,
      success: serverResponse ? serverResponse.status === HTTPResponseCode.OK : false,
      error: {
        code: serverResponse ? null : HTTPResponseCode.Fail,
        message: serverResponse ? null : errorData
      }
    }
    return response;
  }

}

