import { PermissionType, Action, IIncident, ITask, IAction, SessionStorageKeys, PermissionsActionKeys, serviceContainer, GraphQLServiceBase, PermissionServiceBase } from 'web-modules-common';
import * as log from 'loglevel';

export class PermissionService extends PermissionServiceBase {
  private actionFields: Array<string> = [
    `actionKey`,
    `isCollaborator`,
    `isOwner`,
    `isViewable`
  ];

  private actionPermissions = [];
  private sharingActionPermissions = new Map<string, IAction>();

  private generateActionLeanObject(action: any): IAction {
    return new Action(action).toObject();
  }

  public getActions(): Promise<void> {
    const responseHandler = (result) => {
      if (result.data !== null) {

        result.data.actions.forEach(action => {
          this.sharingActionPermissions.set(action.actionKey, this.generateActionLeanObject(action));
        });

        return Promise.resolve();
      }

      return Promise.reject(new Error('permission.service: no data returned from server'));
    };

    const errorHandler = (error) => {
      log.error({ error });
      return Promise.reject(new Error(`permission.service: ${error}`));
    }

    try {
      const graphQLService = serviceContainer.get(GraphQLServiceBase) as GraphQLServiceBase;

     return graphQLService.invokeQuery(
        `GetActions`,
        `actions`,
        null,
        null,
        this.actionFields,
      ).then(result => responseHandler(result))
        .catch(error => { return errorHandler(error) });
    } catch (error) {
      /* istanbul ignore next */
      return errorHandler(error);
    }
  }

  private getDataFromSessionStorage(key: string): number {
    return parseInt(sessionStorage.getItem(key), 10);
  }

  private isActionAllowed(action: string): boolean {
    if (!this.hasPermission(action)) {
      if (this.sharingActionPermissions.has(action) && this.sharingActionPermissions.get(action).isViewable) {
        return false;
      }
    }

    return true;
  }

  private getInfo(): any {
    const currentUserId = this.getDataFromSessionStorage(SessionStorageKeys.CurrentUserID);
    const currentUserJobTitleId = this.getDataFromSessionStorage(SessionStorageKeys.CurrentUserJobTitleId);
    const allCROperatorsJobTitleId = this.getDataFromSessionStorage(SessionStorageKeys.AllCROperatorsJobTitleId);
    const allCROperatorsUserId = this.getDataFromSessionStorage(SessionStorageKeys.AllCROperatorsUserId);

    return { currentUserId, currentUserJobTitleId, allCROperatorsJobTitleId, allCROperatorsUserId };
  }

  private isStakeholder(stakeholders: any): boolean {
    const info = this.getInfo();

    return stakeholders && (
      (stakeholders.users && stakeholders.users.includes(info.allCROperatorsUserId)) ||
      (stakeholders.jobTitles && stakeholders.jobTitles.includes(info.allCROperatorsJobTitleId)) ||
      (stakeholders.users && stakeholders.users.includes(info.currentUserId)) ||
      (stakeholders.jobTitles && stakeholders.jobTitles.includes(info.currentUserJobTitleId)));
  }

  private isCollaborator(collaborators: any): boolean {
    const info = this.getInfo();

    return collaborators && (
      (collaborators.users && collaborators.users.includes(info.allCROperatorsUserId)) ||
      (collaborators.jobTitles && collaborators.jobTitles.includes(info.allCROperatorsJobTitleId)) ||
      (collaborators.users && collaborators.users.includes(info.currentUserId)) ||
      (collaborators.jobTitles && collaborators.jobTitles.includes(info.currentUserJobTitleId)));
  }

  private isAssignee(entity: any): boolean {
    const { currentUserId, allCROperatorsUserId } = this.getInfo();

    return entity && ((entity.assignee && (entity.assignee.id === currentUserId || entity.assignee.id === allCROperatorsUserId)) ||
      (entity.assigneeId && (entity.assigneeId === currentUserId || entity.assigneeId === allCROperatorsUserId)));
  }

  private isAuthorizedBySharing(userPermissionType: PermissionType, action: PermissionsActionKeys): boolean {
    switch (userPermissionType) {
      case PermissionType.Collaborator:
        if (this.sharingActionPermissions.has(action) && this.sharingActionPermissions.get(action).isCollaborator) {
          return true;
        }
        break;
      case PermissionType.Assignee:
      case PermissionType.Stakeholder:
        if (this.sharingActionPermissions.has(action) && this.sharingActionPermissions.get(action).isOwner) {
          return true;
        }
        break;
    }

    return false;
  }

  private getIncidentPermissionType(incident: IIncident): any {
    if (incident) {
      if (this.isAssignee(incident)) {
        return PermissionType.Assignee;
      }

      if (this.isStakeholder(incident.stakeholders)) {
        return PermissionType.Stakeholder;
      }

      if (this.isCollaborator(incident.collaborators)) {
        return PermissionType.Collaborator;
      }
    }
    return false;
  }

  private getTaskPermissionType(task: ITask): any {
    if (task) {
      if (this.isAssignee(task)) {
        return PermissionType.Assignee;
      }

      if (this.isStakeholder(task.stakeholders)) {
        return PermissionType.Stakeholder;
      }
    }
    return false;
  }

  public isActionAllowedForIncident(incident: IIncident, action: PermissionsActionKeys): boolean {
    if (!this.isActionAllowed(action)) return false;

    const userPermissionType = this.getIncidentPermissionType(incident);

    return this.isAuthorizedBySharing(userPermissionType, action);
  }

  public isActionAllowedForTask(incident: IIncident, task: ITask, action: PermissionsActionKeys): boolean {
    if (!this.isActionAllowed(action)) return false;

    let userPermissionType = this.getTaskPermissionType(task);

    if (!userPermissionType) {
      userPermissionType = this.getIncidentPermissionType(incident);
    }

    return this.isAuthorizedBySharing(userPermissionType, action);
  }

  public checkIfUserHasPermissions(incident: any): boolean {
    const userPermissionType = this.getIncidentPermissionType(incident);
    return userPermissionType && (userPermissionType === PermissionType.Assignee || userPermissionType === PermissionType.Stakeholder || userPermissionType === PermissionType.Collaborator);
  };

  public hasPermission(permission): boolean {
    /* istanbul ignore next */
    if (!this.actionPermissions) this.actionPermissions = [];
    return this.actionPermissions && this.actionPermissions.includes(permission);
  }

  public addPermissions(permissions: Array<string>) {
    /* istanbul ignore next */
    if (!this.actionPermissions) this.actionPermissions = [];
    this.actionPermissions = [...this.actionPermissions, ...permissions];
  }

  public clearPermissions(): void {
    this.actionPermissions = [];
  }

  public getPermissions(): Array<string> {
    return this.actionPermissions;
  }
}
