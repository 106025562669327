// eslint-disable-next-line max-classes-per-file
import * as log from 'loglevel';
import jwt from 'jsonwebtoken';
import { Factory } from 'typescript-ioc';
import { BehaviorSubject } from 'rxjs';
import {
  IAuthLoginResponse, 
  Response, 
  ISecurityToken, 
  HTTPResponseCode, 
  LocalStorageKeys, 
  SessionStorageKeys,
  MessageTree,
  serviceContainer,
  EventBusBase,
} from 'web-modules-common';

import { authenticationServiceFactory } from './authenticationServiceFactory.factory';
import LoginConfigurationsAlternative from '../../../configuration/alternatives/Login';
import SessionTimeoutPopupAlternative from '../../../configuration/alternatives/SessionTimeoutPopup';

//@ts-ignore
const sessionStoragePrefix = global.EsriAuthentication &&  global.EsriAuthentication.SessionStoragePrefix || 'arcgisToken'

export class AuthenticationService {

  private sessionTimeoutPopupBuffer: any;
  //@ts-ignore
  private loginConfig: any;
  private securityToken: ISecurityToken;
  private refreshTokenInProgress: boolean;
  private validateTimeout: number;
  private maxRefreshTime: number;
  private service = authenticationServiceFactory();
  userLoggedInSub$ = new BehaviorSubject(false);
 private eventBus;
  constructor() {
    //@ts-ignore
    this.loginConfig = global.LoginConfigurations? global.LoginConfigurations : LoginConfigurationsAlternative;
    // @ts-ignore
    this.sessionTimeoutPopupBuffer = global.SessionTimeoutPopup ? global.SessionTimeoutPopup : SessionTimeoutPopupAlternative;
    this.eventBus = serviceContainer.get(EventBusBase) as EventBusBase;
    this.initializeSecurityTokenFromStorage();
    this.initValidateTimeout(true);
    this.setMaxRefreshTokenLifetime(false);
  }

  async login(userName: string, password: string): Promise<Response> {
    let response;

    try {
      response = await this.service.login(userName, password);
    } catch (error) {
      this.loginErrorHandler(error);
    }
    if (response && response.success) {
      this.loginSuccessHandler(response.data)
    }

    return response;
  }

  async logout(accessToken?: string, refreshToken?: string) {
    let response: IAuthLoginResponse;
    let errorData: any;

    const clearEsriTokens = () => {
      //remove Esri tokens from sessionStorage
      Object.keys(sessionStorage)
      .filter((k) =>  {return k.startsWith(sessionStoragePrefix)})
      .forEach((k) => {
        sessionStorage.removeItem(k);
      });
    }

    const clearStorage = () => {
      localStorage.removeItem(LocalStorageKeys.TokenSlidingLifeTime);
      localStorage.removeItem(LocalStorageKeys.TokenLifetime);
      sessionStorage.removeItem(SessionStorageKeys.CurrentUserData);
      sessionStorage.removeItem(SessionStorageKeys.Notifications);
      sessionStorage.removeItem(SessionStorageKeys.ModulesAlreadyLoaded);
      clearEsriTokens();
    }

    try {
      const securityToken = this.securityToken;

      if (!securityToken) {
        this.eventBus.publish(
          MessageTree.Connectivity.action.failedLogout,
          null,
        );
        return null;
      }

      if (accessToken === undefined) {
        accessToken = securityToken.AccessToken;
      }
      if (refreshToken === undefined) {
        refreshToken = securityToken.RefreshToken;
      }

      response = await this.service.logout(accessToken, refreshToken);
      if (!response.success) {
        throw(response.error)
      }
      clearStorage();
      this.clearValidateTimeout();

      // @ts-ignore
      if (response.success) {
        this.eventBus.publish(
          MessageTree.Connectivity.action.logout,
          {},
        );
        this.userLoggedInSub$.next(false);
      }

    } catch (error) {
      log.error(error);
      errorData = error;
      this.eventBus.publish(
        MessageTree.Connectivity.action.failedLogout,
        null,
      );
    }

    return response;
  }

  async refreshToken(securityToken: ISecurityToken) {
    try {
      const data = await this.service.refreshToken(securityToken);
      if (data instanceof Error) throw data;

      if (data) {
        this.mapSecurityTokenAndUpdateSessionStorage(data, true);
      }
    }
    catch (error) {
      log.error(`Authenticaiton Service, refreshToken, error: ${error}`);
      this.logout().then(() => {
        localStorage.removeItem(LocalStorageKeys.TokenLifetime);
      });
    }
  }

  async getValidAccessToken(config?): Promise<string> {
    let accessToken = this.securityToken && this.securityToken.AccessToken;
    if (!accessToken) {
      this.initializeSecurityTokenFromStorage();
      accessToken = this.securityToken && this.securityToken.AccessToken;
    }

    if (this.refreshTokenInProgress) {
      log.info("Auth service refresh token in progress")
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          resolve(await this.getValidAccessToken())
        }, 1000)
      })

    }
    //if token is found add it to the header
    if (accessToken) {
      // verify that access token is still valid --done
      const isAccessTokenValid = this.isTokenValid(accessToken)
      // if not valid -> refresh token -- done
      if (!isAccessTokenValid) {
        if (!this.refreshTokenInProgress) {
          //await RefreshToken
          log.info(`Authentication before refresh token current time: ${new Date().toString()}`)
          this.refreshTokenInProgress = true;
          await this.refreshToken(this.securityToken);
          log.info("Authentication after refresh token")
          accessToken = this.securityToken && this.securityToken.AccessToken;
          this.updateAccessTokenInIFrames(accessToken);
          this.refreshTokenInProgress = false;
        }
      }

      // if valid -> add the access token to the header
      if (config && config.method !== 'OPTIONS') {
        config.headers.authorization = `Bearer ${accessToken}`;
      }
    } else {
      // navigate to login
      this.logout();
      accessToken = undefined;
    }

    return accessToken;
  }

  async isUserLoggedIn(): Promise<boolean> {
    log.info("Authentication isUserLoggedIn")
    if (!this.securityToken) return false;

    if (this.isTokenValid(this.securityToken.AccessToken)) return true;

    log.info('Authentication isUserLoggedIn trying to refresh token');
    const accessToken = await this.getValidAccessToken();

    // checking if updated token is valid
    if (this.isTokenValid(accessToken)) return true;

    return false;
  }

  /* istanbul ignore next */
  private updateAccessTokenInIFrames = (accessToken: string): void => {
    try {
      const iframes = document.querySelectorAll('iframe');
      for (let index = 0; index < iframes.length; index++) {
        if (iframes[index].id.includes('dashboardData_iframe')) {
          iframes[index].contentWindow.postMessage('allow', window.location.href);
        } else if (iframes[index].src.includes('AccessToken')) {
          iframes[index].contentWindow.postMessage({ type: 'token', data: accessToken }, '*');
        }
      }
    }
    catch (e) {
      log.error(e);
    }
  };

  private isTokenValid(accessToken: string): boolean {
    const decodedAccessToken = jwt.decode(accessToken);
    if (!decodedAccessToken) return false;
    //number is Unix time (seconds)
    const tokenExpirationTime = decodedAccessToken.exp;
    const refreshTokenTimeBuffer = this.loginConfig.Authentication.RefreshTokenTimeBufferInSeconds;

    //The getTime() method returns the number of milliseconds* since the Unix Epoch
    const currentTime = Math.round(((new Date()).getTime()) / 1000) + refreshTokenTimeBuffer;
    return tokenExpirationTime > currentTime && this.maxRefreshTime > currentTime;
  }

  private createResponse(serverResponse: any, errorData: any): Response {
    const responseData = serverResponse ? serverResponse.data : serverResponse
    const response: Response = {
      data: responseData,
      success: serverResponse ? serverResponse.status === HTTPResponseCode.OK : false,
      error: {
        code: serverResponse ? null : HTTPResponseCode.Fail,
        message: serverResponse ? null : errorData
      }
    }
    return response;
  }

  private loginErrorHandler(error) {
    log.error(error);
    this.eventBus.publish(
      MessageTree.Connectivity.action.failedLogin,
      {},
    );
    this.userLoggedInSub$.next(false);
  }

  public loginSuccessHandler(data) {
    this.mapSecurityTokenAndUpdateSessionStorage(data);
    this.userLoggedInSub$.next(true);
  }

  async loginWithExternalToken(token) {
      // @ts-ignore
    return this.service.loginWithExternalToken(token)
  }

  /* istanbul ignore next */
  initValidateTimeout(isInitial = false) {
    const slidingInterval = this.calcInterval();
    if (!slidingInterval) return;
    if (slidingInterval <= 0) {
      if (!isInitial) {
        log.info('Authentication Service: initiating sliding window timeout');
        this.clearValidateTimeout();
        this.initSlidingWindowTimeout.bind(this);
      }
    } else {
      clearTimeout(this.validateTimeout);
      log.info(`Authentication Service: clearing sliding window timeout before reset timeout`)
      this.validateTimeout = setTimeout(this.initSlidingWindowTimeout.bind(this), slidingInterval)
    }
  }
  /* istanbul ignore next */
  private clearValidateTimeout(reInitTimeOut = false) {
    if (this.validateTimeout) {
      log.info('Authentication Service: clearing sliding window timeout');
      clearTimeout(this.validateTimeout);
      this.validateTimeout = null;
      reInitTimeOut && this.initValidateTimeout(false);
    }
  }

  private setSessionStorageUserData(securityToken: ISecurityToken): void {
    if (securityToken) {
      sessionStorage.setItem(SessionStorageKeys.CurrentUserData, JSON.stringify(securityToken));
      this.securityToken = securityToken;
    }
  }

  private securityTokenMapper(data: any): Array<ISecurityToken> | never {
    let retSecurityTokens: Array<ISecurityToken> = [] as Array<ISecurityToken>;
    try {
      const parsedData = JSON.parse(data);
      const securityToken: ISecurityToken = {} as ISecurityToken;
      securityToken.AccessToken = parsedData.access_token ? parsedData.access_token : null;
      securityToken.ExpiresIn = parsedData.expires_in ? parsedData.expires_in : null;
      securityToken.RefreshToken = parsedData.refresh_token ? parsedData.refresh_token : null;
      securityToken.TimeStamp = Date.now();
      retSecurityTokens.push(securityToken);
    } catch (error) {
      log.error(error);
      retSecurityTokens = null;
    }

    return retSecurityTokens;
  }

  private updateSessionStorageWithCurrentUserId(decodedAccessToken) {
    decodedAccessToken && decodedAccessToken.user_id && sessionStorage.setItem(SessionStorageKeys.CurrentUserID, decodedAccessToken.user_id);
  }

  private setMaxRefreshTokenLifetime(isFromLoginFlow: boolean = true) {
    const storedMaxRefreshTokenTime = localStorage.getItem(LocalStorageKeys.TokenLifetime);

    if (storedMaxRefreshTokenTime && !isFromLoginFlow) {
      const { TokenLifetime, LoginTime } = JSON.parse(storedMaxRefreshTokenTime);
      this.maxRefreshTime = LoginTime + TokenLifetime;
    }
    else {
      const accessToken = this.securityToken && this.securityToken.AccessToken;

      if (accessToken) {
        const decodedAccessToken = jwt.decode(accessToken);
        const maxRefreshExpirationTime = +decodedAccessToken.max_refresh_token_liftime;

        localStorage.setItem(LocalStorageKeys.TokenLifetime, JSON.stringify({
          TokenLifetime: maxRefreshExpirationTime,
          LoginTime: Math.round(((new Date()).getTime()) / 1000)
        }));
        this.maxRefreshTime = Math.round(((new Date()).getTime()) / 1000) + maxRefreshExpirationTime;
      }
    }
    log.info(`Authentication service: expected maxRefresh time: ${new Date(this.maxRefreshTime * 1000)}`)
  }

  private mapSecurityTokenAndUpdateSessionStorage(data, isTokenRefresh = false) {
    const securityTokenData = this.securityTokenMapper(JSON.stringify(data))[0];
    const decodedAccessToken = jwt.decode(securityTokenData.AccessToken);

    localStorage.setItem(LocalStorageKeys.TokenSlidingLifeTime, JSON.stringify({
      SlidingRefreshTokenLifetime: decodedAccessToken ? decodedAccessToken.sliding_refrsh_token_liftime : null,
      TimeStamp: Date.now()
    }));

    this.clearValidateTimeout(isTokenRefresh);
    this.initValidateTimeout(false);
    log.info('Authentication Service: New sliding timeout is set')
    this.setSessionStorageUserData(securityTokenData);
    this.updateSessionStorageWithCurrentUserId(decodedAccessToken);
    this.setMaxRefreshTokenLifetime(!isTokenRefresh);
    if(isTokenRefresh){
      this.eventBus.publish(
        MessageTree.Connectivity.action.tokenIsRefreshed,
        {},
      );
    }
  }

  private calcInterval(): number {
    let interval = null;
    const tokenSlidingStr = localStorage.getItem(LocalStorageKeys.TokenSlidingLifeTime);
    const buffer = this.sessionTimeoutPopupBuffer && this.sessionTimeoutPopupBuffer.messageBoxCloseTimeout || 60000;
    if (tokenSlidingStr) {
      const TokenSlidingLifeTime = JSON.parse(tokenSlidingStr);
      const { TimeStamp, SlidingRefreshTokenLifetime } = TokenSlidingLifeTime;
      interval = (SlidingRefreshTokenLifetime * 1000) - buffer - (Date.now() - TimeStamp);
    }
    return interval;
  }


  /* istanbul ignore next */
  private initSlidingWindowTimeout() {
    if(!this.refreshTokenInProgress){
      this.eventBus.publish(
        MessageTree.Connectivity.action.initSlidingWindowTimeout,
        {},
      );
      log.info(`Authenticaiton Service: Sliding window popup is now activated - ${new Date()} `);
    }
  }

  private initializeSecurityTokenFromStorage() {
    const storagedToken = sessionStorage.getItem(SessionStorageKeys.CurrentUserData);
    if (storagedToken) {
      this.securityToken = JSON.parse(storagedToken);
    }
  }

}

