import React from 'react';
import { useIntl } from 'react-intl';
import {
  UserInfoHeaderWrapper,
  UserInfoContentWrapper,
  UserInfoHeaderLabel,
  LogoutButton,
  LogoutImage,
  InitialsComponentWrapper,
  UserInfoFieldValueWrapper,
  FieldsWrapper,
  FieldLabel,
  ValueLabel,
} from './headerWidgetStyles';
import { Divider } from 'semantic-ui-react';
import { SessionStorageKeys, InitialsComponent, styles } from 'web-modules-common';

interface UserInfoProps {
  invokeLogout?: () => void;
  isRTL: boolean;
}

const UserInfoContent: React.FC<UserInfoProps> = (props: UserInfoProps) => {
  const intl = useIntl();
  const onLogoutClick = (): void => {
    props.invokeLogout();
  };

  const currentUserName = sessionStorage.getItem(
    SessionStorageKeys.CurrentUserName,
  );

  const currentUserJobTitle = sessionStorage.getItem(
    SessionStorageKeys.CurrentUserJobTitleName,
  );

  return (
    <UserInfoContentWrapper>
      <UserInfoHeaderWrapper>
        <UserInfoHeaderLabel>
          {intl.formatMessage({ id: 'userInfo.Account' })}
        </UserInfoHeaderLabel>
        <LogoutButton onClick={onLogoutClick} id="logout_button">
          {intl.formatMessage({ id: 'userInfo.LogOut' })}
          &nbsp;&nbsp;
          <LogoutImage isrtl={props.isRTL}>
            <styles.image.LogoutIcon />
          </LogoutImage>
        </LogoutButton>
      </UserInfoHeaderWrapper>
      <Divider />
      <InitialsComponentWrapper>
        <InitialsComponent name={currentUserName} />
      </InitialsComponentWrapper>
      <UserInfoFieldValueWrapper>
        <FieldsWrapper>
          <FieldLabel>
            {intl.formatMessage({ id: 'userInfo.FullName' })}:
          </FieldLabel>
          <FieldLabel>
            {intl.formatMessage({ id: 'userInfo.JobTitle' })}:
          </FieldLabel>
        </FieldsWrapper>
        <FieldsWrapper>
          <ValueLabel id="current_user_name">
            {currentUserName}
          </ValueLabel>
          <ValueLabel id="current_user_job_title">
            {currentUserJobTitle}
          </ValueLabel>
        </FieldsWrapper>
      </UserInfoFieldValueWrapper>
    </UserInfoContentWrapper>
  );
};

export default UserInfoContent;
