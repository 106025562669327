import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import {
   Button,
   styles,
   LoginError,
   SessionStorageKeys,
   I18n
} from 'web-modules-common';
import  Logo  from '../../../../assets/images/logo.png';
import { FormattedMessage, useIntl } from 'react-intl';
import * as log from 'loglevel';
import AppConfig from '../../../../../configuration/AppConfig.json';

import {
  InputWrapperLogin,
  LoginEmptyInputError,
  LoginFormError,
  LoginFormWrapper,
  LoginIcon,
  LoginInput,
  LoginInputWrapper,
  LoginLanguageOption,
  LoginLanguageSelect,
  LoginLogo,
} from './FormStyles';
import { azureService } from '../../../../services';

interface FormProps {
  userName: string;
  password: string;
  onSubmit: (username: string, password: string) => void;
  onLanguageChanged: (
    language: string,
    userNameUpdated: string,
    passwordUpdated: string,
  ) => void;
  showError: boolean;
  errorCaption: string;
  errorMessageSSL?: string;
  setLoading: (state: boolean) => void;
  showGeneralError: boolean;
  setShowGeneralError: (state: boolean) => void;
  isAzureLogin?: boolean;
}

const LoginForm: FC<FormProps> = (props: FormProps) => {
  const intl = useIntl();
  const [userName, setUserName] = useState(props.userName);
  const [password, setPassword] = useState(props.password);
  const isMountedRef = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState(
    I18n.getInstance().getLocale(),
  );

  const [showEmptyUsernameError, setShowEmptyUsernameError] = useState(false);
  const [showEmptyPasswordError, setShowEmptyPasswordError] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;

    const loadErrorIndication = localStorage.getItem(SessionStorageKeys.HasLoadError);
    /* istanbul ignore next */
    if (loadErrorIndication) {
      props.setShowGeneralError(true);
      localStorage.removeItem(SessionStorageKeys.HasLoadError);
    }

    return (): void => {
      isMountedRef.current = false;
    };
  }, []);

  /* istanbul ignore next */
  const loginWithAzure = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.setLoading(true);

    azureService.login()
      .then((r) => {
        props.setLoading(false);
        props.setShowGeneralError(false);
      })
      .catch((e) => {
        props.setLoading(false);
        props.setShowGeneralError(true);
        log.error('azureService error on login: ',e);
      });
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    switch (event.target.name) {
      case 'username':
        setUserName(event.target.value);
        setShowEmptyUsernameError(false);
        break;
      case 'password':
        setPassword(event.target.value);
        setShowEmptyPasswordError(false);
        break;
    }
  };

  const handleSubmit = (event: React.SyntheticEvent): void => {
    localStorage.removeItem(SessionStorageKeys.HasLoadError);
    props.setShowGeneralError(false);

    if (userName.length === 0) {
      setShowEmptyUsernameError(true);
    }
    if (password.length === 0) {
      setShowEmptyPasswordError(true);
    }

    props.onSubmit(userName, password);
    event.preventDefault(); // prevent the form from submitting
  };

  const handleSelectChange = (
    event: ChangeEvent<HTMLSelectElement>,
  ): void => {
    setSelectedLanguage(event.target.value);
    props.onLanguageChanged(event.target.value, userName, password);
  };

  const submitOnEnter = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const submitOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    handleSubmit(event);
  };

  const generateOptions = () => {
    return AppConfig.languages.map(language => {
      return (
        <LoginLanguageOption key={language.key} value={language.key}>
          {language.value}
        </LoginLanguageOption>
      );
    });
  };

  const displayError = (id: string) => {
    return (
      <LoginFormError data-automation={'incorrect_login'}>
        {intl.formatMessage({ id: id })}
      </LoginFormError>
    );
  }

  const renderError = () => {
    /* istanbul ignore next */
    if (props.showGeneralError) return displayError('login.generalError' );

    if (!props.showError) return <></>;

    /* istanbul ignore next */
    if (props.errorMessageSSL) return displayError(props.errorMessageSSL || 'login.error' );


    switch (props.errorCaption) {

      case LoginError.DefaultError:
        return displayError('login.generalError' );

      case LoginError.InvalidUserNameOrPassword:
        /* istanbul ignore next */
        return displayError('login.error' );

      case LoginError.MaxAttemptsError:
        return displayError('login.errorMaxAttempt');

      case LoginError.Forbidden:
        /* istanbul ignore next */
        return displayError('login.errorForbiddenTerminal');

     default:
       /* istanbul ignore next */
        return displayError('login.generalError');
    }
  }
  return (
    <LoginFormWrapper
      isRTL={I18n.getInstance().isRTL(selectedLanguage)}
    >
      <LoginLogo src={Logo} alt="Qognify logo"/>

      {!props.isAzureLogin &&
        (
          <>
            <LoginInputWrapper>
              <LoginInput
                type="text"
                placeholder={intl.formatMessage({ id: 'login.userName' })}
                name="username"
                value={userName}
                onChange={handleInputChange}
                onKeyPress={submitOnEnter}
              />
              <LoginIcon>
                <styles.icon.IcnLogin />
              </LoginIcon>
              {showEmptyUsernameError && (
                <LoginEmptyInputError id="userNameInputError">
                  {intl.formatMessage({ id: 'login.emptyUsenameError' })}
                </LoginEmptyInputError>
              )}
            </LoginInputWrapper>

            <LoginInputWrapper>
              <LoginInput
                type="password"
                placeholder={intl.formatMessage({ id: 'login.password' })}
                name="password"
                value={password}
                onChange={handleInputChange}
                onKeyPress={submitOnEnter}
              />
              <LoginIcon>
                <styles.icon.IcnPassword />
              </LoginIcon>

              {showEmptyPasswordError && (
                <LoginEmptyInputError id="passwordInputError">
                  {intl.formatMessage({ id: 'login.emptyPasswordError' })}
                </LoginEmptyInputError>
              )}
            </LoginInputWrapper>
          </>
        )
      }


      <LoginInputWrapper>
        <LoginLanguageSelect
          id="LanguageSelect"
          onChange={handleSelectChange}
          value={selectedLanguage}
        >
          {generateOptions()}
        </LoginLanguageSelect>
      </LoginInputWrapper>

      {props.isAzureLogin ?
          <InputWrapperLogin id={'azure-login-btn'} style={{ justifyContent: 'center' }}>
            <Button
              text={<FormattedMessage id="login.connect.azure"/>}
              onClick={(e) => loginWithAzure(e)}
            />
          </InputWrapperLogin>
         :
          <InputWrapperLogin>
            <Button
              text={<FormattedMessage id="login.connect"/>}
              onClick={submitOnClick}
            />
          </InputWrapperLogin>
      }

      {renderError()}


    </LoginFormWrapper>
  );
};

export default LoginForm;
