import styled, { css } from 'styled-components';

import {styles} from "web-modules-common";

const placeholder = css`
  color: ${styles.color.shade.WHITE06};
  font-size: ${styles.typographyScale.TYPE16};
`;

const inputWrapper = css`
  max-width: 350px;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
`;

const LoginFormWrapper = styled.form<{ isRTL: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props): string => props.isRTL ? 'var(--dark-gradiant-rtl);' : 'var(--dark-gradiant-ltr);'};
  width: 100%;
  min-height: 350px;
  height: 100vh;
  margin: 0;
  direction: ${(props): string => (props.isRTL ? 'rtl' : 'ltr')};
`;

const LoginInput = styled.input`
  max-width: 370px;
  width: 100%;
  margin-bottom: 32px;
  height: 40px;
  text-indent: 42px;
  border: 0;
  border-radius: ${styles.borderRadius.PRIMARY};
  background: ${styles.color.shade.WHITE005};
  transition: ${styles.transition.PRIMARY};
  font-size: ${styles.typographyScale.TYPE16};
  line-height: ${styles.lineHeight.LHEIGHT1_5};
  color: ${styles.color.shade.WHITE};
  &:focus,
  &:hover {
    outline: 0;
    background: ${styles.color.shade.WHITE01};
  }
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    ${placeholder}
  }
`;

const LoginLogo = styled.img`
  width: 150px;
  margin-bottom: 64px;
`;

const LoginInputWrapper = styled.div`
  ${inputWrapper}
`;

const InputWrapperLogin = styled.div`
  margin-top: 20px;
  ${inputWrapper};
  display: flex;
  justify-content: flex-end;
`;

const LoginIcon = styled.div`
  height: 16px;
  display: block;
  margin: 0 10px;
  position: absolute;
  top: 12px;
`;

const LoginFormError = styled.div`
  border: 2px solid ${styles.color.utility.ERROR};
  background: ${styles.color.utility.ERROR};
  color: ${styles.color.shade.WHITE};
  width: 350px;
  margin: 24px 0 0;
  padding: 3px 6px;
  border-radius: ${styles.borderRadius.PRIMARY};
`;
LoginFormError.displayName = 'StyledLoginFormError';

const LoginEmptyInputError = styled.div`
  color: ${styles.color.utility.ERROR};
  width: auto;
  padding: 3px 6px;
  position: absolute;
  top: 40px;
  id: ${(props): string => props.id}
`;

const LoginLanguageSelect = styled.select`
  width: inherit;
  height: 40px;
  background: ${styles.color.shade.WHITE005};
  color: gray;
  font-size: 16px;
  border: none;
  outline:0;
  &:focus,
  &:hover {
    outline: 0;
    background: ${styles.color.shade.WHITE01};
  }
`;

const LoginLanguageOption = styled.option`
  color: ${styles.color.shade.DARK_FONT};
  background: ${styles.color.shade.WHITE01};
  font-weight: normal;
  display: flex;
  min-height: 20px;
  margin: 0 2px 1px;
`;

export {
  LoginFormWrapper,
  LoginInput,
  LoginLogo,
  LoginIcon,
  LoginInputWrapper,
  InputWrapperLogin,
  LoginFormError,
  LoginLanguageSelect,
  LoginLanguageOption,
  LoginEmptyInputError,
};
