import styled from 'styled-components';

const NotificationBubble = styled.div<{
  isRead: boolean,
  isRTL: boolean
}>`
  position: absolute;
  right: ${(props): string => (props.isRTL ? 'auto': '-20px')};
  left: ${(props): string => (props.isRTL ? '-20px': 'auto')};
  top: -5px;
  width: 22px;
  height: 22px;
  background: ${(props): string => (props.isRead ? 'transparent' : '#FF3B6A')};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 24px;
  color: rgb(255 255 255);
  border-radius: 50%;
}
`;

export {
  NotificationBubble,
};
