import { ModuleType } from 'web-modules-common';
import { BaseModule } from '../../BaseModule';
import { ModuleFactory } from './ModuleFactory';

// ModuleManager - reg/un-reg each module for the module queue
// deploy / un-deploy each module
// singleton instance
export class ModuleManager {
  private static moduleManager: ModuleManager;
  
  private moduleElementsMap: Map<string, BaseModule> = new Map<
    string,
    BaseModule
  >();

  private moduleFactory: ModuleFactory;

  private constructor() {
    this.moduleFactory = new ModuleFactory();
  }

  public static getInstance(): ModuleManager {
    if (!this.moduleManager) {
      this.moduleManager = new ModuleManager();
    }
    return this.moduleManager;
  }

  public async addModules(): Promise <Map<string, BaseModule>> {
    this.moduleElementsMap = await this.moduleFactory.loadModulesComponents();
    return this.moduleElementsMap;
  }

  public async addModule(module: BaseModule): Promise <BaseModule> {
    const widgetElement = await this.moduleFactory.loadModuleComponent(module);
    this.moduleElementsMap.set(widgetElement.id, widgetElement)
    return widgetElement;
  }


  public removeModule(moduleID: string): boolean | never {
    const retValue = true;

    if (!this.isModuleIDExits(moduleID)) return false;

    this.moduleElementsMap = this.moduleFactory.removeModuleComponent(
      this.moduleElementsMap,
      moduleID,
    );

    return retValue;
  }

  public cleanModuleElements(): void {
    // clean loaded modules
    this.moduleElementsMap.clear();
  }

  public getSidebarProperties(): Array<BaseModule> {
    const retValue: Array<BaseModule> = [];
    this.moduleElementsMap.forEach((module, moduleID) => {
      retValue.push(module);
    });

    return retValue;
  }

  public moduleElements(): Map<string, BaseModule> {
    return this.moduleElementsMap;
  }

  public initiateThirdPartyLogin (userName: string, password: string): void {
      this.moduleElementsMap.forEach(module=>{
        if(module.serviceInstance.initiateThirdPartyLogin){
          module.serviceInstance.initiateThirdPartyLogin(userName,password);
        }
      })
  }

  public getModuleIDByModuleType(moduleType: ModuleType): string {
    for (const module of this.moduleElementsMap.values()){
      if (module.moduleType === moduleType){
        return module.moduleID;
      } 
    }

    return '';
  }

  private isModuleIDExits(moduleID: string): boolean {
    if (this.moduleElementsMap.get(moduleID) !== undefined) {
      return true;
    }
    return false;
  }
}
