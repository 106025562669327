import React, { FC } from 'react';
import './styles.scss';
import { useIntl } from 'react-intl';
import { notificationService ,eventBus } from '../../../../../services';
import {DateTimeComponent, INotification, MessageTree} from 'web-modules-common';

interface IProps extends INotification {
  closeNotifications: () => void,
  handleMarkAsRead: (id: number) => void
};

const NotificationItem: FC<IProps> = (props) => {
  const { notificationIcon, notificationText, notificationTime, id, notificationData, closeNotifications, handleMarkAsRead } = props;
  const intl =     useIntl();

  const checkTypeofTime = (time:any): Date => {
    if(time instanceof Date){
      return time;
    }
    if(typeof time === 'string'){
      return !Number.isNaN(new Date(time).getDate()) && new Date(time) || new Date();
    }
    return new Date();
  };

  const time = checkTypeofTime(notificationTime);

  const handleMarkAsReadClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    handleMarkAsRead(id);
  };

  const onNotificationClick = (): void => {
    eventBus.publish(
      MessageTree.Notification.action.notificationSelected,
      {
        id,
        notificationData,
        onSelect: () => notificationService.updateSelectedNotification(id) 
      },
    )
    handleMarkAsRead(id)
    closeNotifications();
  };

  const renderNotificationContent = () => {
    if(props.notificationCustomRenderer){
      return props.notificationCustomRenderer(props);
    };

    return (
      <div className="d-flex jc-fs ai-fs">
        <div className="img"><img src={notificationIcon} alt="" /></div>
        <div className="notificationText" dangerouslySetInnerHTML={{ __html: notificationText }} />
      </div>
    )
  }

  return (
    <div className="notificationItem" onClick={onNotificationClick} aria-hidden="true">
      <div className="head">
        <div className="d-flex ai-c jc-sb">
          <div className="dateTimeText">
             <DateTimeComponent date={time} dateTimeStyle={{fontSize: 'inherit'}} />
          </div>
          <button className="markAsRead" type="button" onClick={handleMarkAsReadClick}>
            {intl.formatMessage({ id: 'notifications.markAsRead' })}
          </button>
        </div>
      </div>
      { renderNotificationContent() }
    </div>
  );
};

export default NotificationItem;
