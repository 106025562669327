import gql from 'graphql-tag';
import * as log from 'loglevel';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  from,
} from 'apollo-boost';
import { onError } from "apollo-link-error"
import { setContext } from "apollo-link-context";
import { DefaultOptions } from 'apollo-client/ApolloClient';
import { createIntl, createIntlCache } from 'react-intl';
import { I18n, MessageTree, GraphQLServiceBase, serviceContainer, AuthenticationServiceBase, EventBusBase } from 'web-modules-common';
import LoginConfigurationsAlternative from '../../../configuration/alternatives/Login';

const locale = I18n.getInstance().getLocale();
const messages = I18n.getInstance().getMessages(locale);
const cache = createIntlCache();
const intl = createIntl({ locale, messages }, cache)

export class GraphQLService extends GraphQLServiceBase {
  private client: any;
  private loginConfig;
  private authenticationService; 
  private eventBus;

  public constructor() {
    super();
    this.authenticationService = serviceContainer.get(AuthenticationServiceBase) as AuthenticationServiceBase;
    this.eventBus = serviceContainer.get(EventBusBase) as EventBusBase;
    //@ts-ignore
    this.loginConfig = global.LoginConfigurations? global.LoginConfigurations : LoginConfigurationsAlternative;
    this.initializeApolloClient();
  }

  private initializeApolloClient() {
    const authenticationMiddleware = setContext(
      request => new Promise((resolve, reject) => {
        this.authenticationService.getValidAccessToken().then(token => resolve({ headers: { authorization: `Bearer ${token}` } }));
      })
    );

    const link = new HttpLink({
      uri: `${this.loginConfig.endPoints.WebApi.ServerUrl}/${this.loginConfig.endPoints.WebApi.GraphQLPrefix}`,
    });

    /* istanbul ignore next */
    const getRegexMess = (message: any, regex: RegExp, index: number) => {
      if (message && message.startsWith('[?:')) {
        return regex.exec(message)[index];
      }
      return false;
    }

    /* istanbul ignore next */
    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {

      if (graphQLErrors && graphQLErrors.length > 0) {
        const defaultMessage = intl.formatMessage({ id: 'ErrorOccurred' });
        const messageRegex = /(?<=\[?:)(.*?)(?=\])/igm;
        graphQLErrors.map(({ message, locations, path }) => {
          let formatRegexMess: string | boolean;

          try {
            formatRegexMess = getRegexMess(message, messageRegex, 1);
          } catch (error) {
            formatRegexMess = getRegexMess(message, messageRegex, 0);
          }

          const messageToFormat = formatRegexMess || message;
          log.error(`[GraphQL error]: Message: ${intl.formatMessage({ id: messageToFormat, defaultMessage })}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
        }
        );

        if (this.isInvalidGrantError(graphQLErrors[0].message)) {
          // logout of the application
          this.authenticationService.logout();
        }
        else {
          this.eventBus.publish(
            MessageTree.Global.action.showGraphQLError,
            graphQLErrors[0].message
          );
        }

        return null;
      }
      else if (networkError) {
        this.eventBus.publish(
          MessageTree.Global.action.showGraphQLError,
          networkError
        );
        log.error(`[Network error]: ${networkError}`);
      }
      return forward(operation);
    });


    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
    this.client = new ApolloClient({
      cache: new InMemoryCache(),
      link: from([authenticationMiddleware, errorLink, link]),
      defaultOptions: defaultOptions,
    });
  }

  public async invokeMutate(
    queryName: string,
    entityName: string,
    fieldNamesAndData?: Array<any>,
    fieldsNames?: Array<string>,
  ): Promise<any> {
    let response: any;
    try {
      const mutationString = `mutation {
        ${queryName}(${entityName}:{
          ${this.generateMutationData(fieldNamesAndData)} }) {
           ${this.generateFieldsName(fieldsNames)}
        }
      }`;

      return this.client.mutate({
        mutation: gql(mutationString),
      });
    } catch (error) {
      /* istanbul ignore next */
      log.error(error);
      /* istanbul ignore next */
      //@ts-ignore
      throw new Error(error);
    }
  }

  public async invokeMutateWithNoEntity(queryName: string, fieldNamesAndData?: Array<any>): Promise<any> {
    let response: any;
    try {
      const mutationString = `
                mutation {${queryName} (
                  ${this.generateMutationData(fieldNamesAndData)} 
      )}
      `;

      return this.client.mutate({
        mutation: gql(mutationString),
      });
    } catch (error) {
      /* istanbul ignore next */
      log.error(error);
      /* istanbul ignore next */
      //@ts-ignore
      throw new Error(error);
    }
    /* istanbul ignore next */
    return response;
  }

  public async invokeQueryForMultipleEntities(
    queryName: string,
    entityNames: string[],
    specificSearchEntityFieldNames: string[],
    specificSearchEntityFieldValues: any[],
    fieldsNames?: Array<string[]>,
  ): Promise<any> {
    let response: any;
    try {
      let subQueries = '';

      for (let index = 0; index < entityNames.length; index++) {
        const subQuery = `${entityNames[index]
          }${this.generateSpecificField(
            specificSearchEntityFieldNames[index],
            specificSearchEntityFieldValues[index],
          )} {
              ${this.generateFieldsName(fieldsNames[index])}
            }`;
        subQueries += subQuery;
      }

      const queryString = `
          query ${queryName} {
            ${subQueries}
             }
        `;

      return this.client.query({
        query: gql(queryString),
      });
    } catch (error) {
      /* istanbul ignore next */
      log.error(error);
      /* istanbul ignore next */
      //@ts-ignore
      throw new Error(error);
    }
  }

  public async invokeQueryWithMultipleParameters(
    queryName: string,
    entityName: string,
    parameterNames: Array<string>,
    parameterValues: Array<any>,
    fieldsNames?: Array<string>

  ): Promise<any> {
    let response: any;
    try {
      const queryString = `
        query ${queryName} {
          ${entityName}(${this.generateQueryParamNamesWithValues(parameterNames, parameterValues)}) {
            ${this.generateFieldsName(fieldsNames)}
          }
        }
      `;

      return this.client.query({
        query: gql(queryString),
      });
    } catch (error) {
      /* instanbul ignore next */
      log.error(error);
      //@ts-ignore
      throw new Error(error);
    }
  }

  public async invokeQuery(
    queryName: string,
    entityName: string,
    specificSearchEntityFieldName?: string,
    specificSearchEntityFieldValue?: any,
    fieldsNames?: Array<string>,
  ): Promise<any> {
    let response: any;
    try {
      const queryString = `
          query ${queryName} {
            ${entityName}${this.generateSpecificField(
        specificSearchEntityFieldName,
        specificSearchEntityFieldValue,
      )} {
              ${this.generateFieldsName(fieldsNames)}
            }
          }
        `;

      return this.client.query({
        query: gql(queryString),
      });
    } catch (error) {
      /* istanbul ignore next */
      log.error(error);
      /* istanbul ignore next */
      //@ts-ignore
      throw new Error(error);
    }
  }

  private generateQueryParamNamesWithValues(
    parameterNames: Array<string>,
    parameterValues: Array<string>
  ): string {
    let returnData = ``;
    if (!parameterNames || !parameterValues) {
      return returnData;
    }

    let parameterNamesAndValues = ``;
    for (let i = 0; i < parameterNames.length; i++) {
      parameterNamesAndValues += `${parameterNames[i]}: ${parameterValues[i]},`;
    }

    if (parameterNamesAndValues.length > 0) {
      returnData = `${parameterNamesAndValues.slice(0, -1)}`;
    }

    return returnData;
  }

  /* istanbul ignore next */
  private generateSpecificField(
    specificSearchEntityFieldName: string,
    specificSearchEntityFieldValue: any,
  ): string {
    return specificSearchEntityFieldName &&
      specificSearchEntityFieldValue
      ? `(${specificSearchEntityFieldName}: ${specificSearchEntityFieldValue})`
      : ``;
  }

  private generateFieldsName(fieldsNames: Array<string>): string {
    let returnFieldsNames = ``;
    fieldsNames.forEach(field => {
      returnFieldsNames += `${field}\n`;
    });

    return returnFieldsNames;
  }

  /* istanbul ignore next */
  private generateMutationData(
    fieldNamesAndData: Array<any>,
  ): string {
    let returnData = ``;

    let paramterNamesAndData = ``;
    for (let i = 1; i < fieldNamesAndData.length; i += 2) {
      paramterNamesAndData += `${fieldNamesAndData[i - 1]}:${fieldNamesAndData[i]
        },`;
    }

    if (paramterNamesAndData.length > 0) {
      returnData = returnData.concat(
        `${paramterNamesAndData.slice(0, -1)}`,
      );
    }

    return returnData;
  }

  /* istanbul ignore next */
  private isInvalidGrantError(error: string): boolean {
    return error
      .toLowerCase()
      .includes(`security_access_token_expired`) ||
      error
        .toLowerCase()
        .includes(`ex_security_illegal_session`) ||
      error.toLowerCase().includes(`grant`);
  }
}
