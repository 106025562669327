/* istanbul ignore file */
import data from './version.json';

const CACHE_TOKEN = 'CACHE_TOKEN';

(() => {
  const token = localStorage.getItem(CACHE_TOKEN);
  
  function onClearCache() {
    if( window.location.protocol === 'http:' || (!window.caches || typeof caches === 'undefined') ) return;
    caches.keys()
      .then((keyList) => Promise.all(keyList.map((key) => caches.delete(key)))
        .then(() => {
          window.location.reload();
        }))
  }

  if (token !== data.token) {
    onClearCache()
  }

  localStorage.setItem(CACHE_TOKEN, data.token)
})()

