import styled from 'styled-components';
import {styles} from 'web-modules-common';

const StyledApp = styled.div<{ isRTL: boolean }>`
  background: ${(props): string => props.isRTL ? 'var(--dark-gradiant-rtl);' : 'var(--dark-gradiant-ltr);'};
  min-height: 100%;
  text-align: center;
  height: 100%;
  direction: ${(props): string => props.isRTL ? 'rtl' : 'ltr'};
`;

const StyledAppHeader = styled.div<{ isRTL: boolean }>`
  height: ${styles.sizes.HEADER};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-${(props): string => (props.isRTL ? 'left' : 'right')}: 30px;
`;

const StyledAppBody = styled.div`
  display: flex;
`;

const WorkingAreaWrapper = styled.div`
  background-color: ${styles.color.shade.PALE};
  color: ${styles.color.shade.DARK};
  width: calc(100% - ${styles.sizes.SIDEBAR});
  height: calc(100vh - ${styles.sizes.HEADER});
  border-radius: ${styles.borderRadius.PRIMARY}
    ${styles.borderRadius.PRIMARY} 0 0;
  overflow-y: auto;
`;

export {
  StyledApp,
  StyledAppBody,
  StyledAppHeader,
  WorkingAreaWrapper,
};
