import React, { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { LoginForm } from '../..';
import { azureService, authenticationService, eventBus } from '../../../../services';
import {
  LoginError,
  Loader,
  I18n,
  Response,
  SessionStorageKeys
} from 'web-modules-common';
import * as log from 'loglevel';
import { ModuleManager } from '../../../../../modules/infrastructure/ModuleManager';
import LoginConfigurationsAlternative from '../../../../../configuration/alternatives/Login';

// @ts-ignore
const loginConfig = global.LoginConfigurations ? global.LoginConfigurations : LoginConfigurationsAlternative;
const isAzureLogin = loginConfig.loginProvider === loginConfig.providers.AZURE.key;

const LoginPage: FC = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorCaption, setErrorCaption] = useState('');
  const [errorMessageSSL, setErrorMessageSSL] = useState(null);
  const isMountedRef = useRef(null);
  const [locale, setLocale] = useState(I18n.getInstance().getLocale());
  const [showGeneralError, setShowGeneralError] = useState(false);
  // const history = useHistory();
  const localeKey = `locale`;

  useEffect(() => {
    isMountedRef.current = true;

    return (): void => {
      isMountedRef.current = false;
    };
  }, []);

  /* istanbul ignore next */
  const createErrorDescription = (res: Response): string | null => {
    try {
      const errorDescription: string = res.error.message['response'].data.error_description;
      if (
        (!errorDescription && res.error.message['response'].data.error === "invalid_grant") ||
        errorDescription &&
        ((errorDescription.toLocaleLowerCase().includes('password') || errorDescription.includes('not defined in Situator') || errorDescription.includes('invalid AD credentials')))
      ) {
        return LoginError.InvalidUserNameOrPassword;
      }

      if (errorDescription && errorDescription.toLocaleLowerCase().includes('forbidden')) {
        return LoginError.Forbidden;
      }

      return errorDescription || null;
    } catch (e) {
      return null;
    }
  };

  /* istanbul ignore next */
  const generateError = (errorDescr: string | null): string => {
    if (!errorDescr) {
      return LoginError.DefaultError;
    }

    if (errorDescr.includes(LoginError.MaxAttemptsErrorDescription)) {
      return LoginError.MaxAttemptsError;
    }

    if (errorDescr.includes(LoginError.InvalidUserNameOrPassword)) {
      return LoginError.InvalidUserNameOrPassword;
    }

    if (errorDescr.includes(LoginError.Forbidden)) {
      return LoginError.Forbidden;
    }

    return LoginError.DefaultError;
  };

  /* istanbul ignore next */
  const onSubmit = (updateUserName: string, updatePassword: string): void => {
    const invokeLogin = async () => {
      setLoading(true);
      const res = await authenticationService.login(updateUserName, updatePassword);

      if (isMountedRef.current) {
        setLoading(false);
        if (res) {
          if (!res.success) {
            log.error(`Login failed. Error: ${JSON.stringify(res.error.message['response'].data)}`);
            setErrorCaption(generateError(createErrorDescription(res)));
            setShowError(true);

            // @ts-ignore
            try {
              if (res.error &&
                res.error.message &&
                res.error.message['response'] &&
                res.error.message['response'].data &&
                res.error.message['response'].data.includes('SSL is required')) {
                setErrorMessageSSL('login.SSLError');
              } else {
                setErrorMessageSSL(null);
              }
            } catch (e) {
              setErrorMessageSSL(null);
            }

          }
        }

        else {
          log.error(`Login failed. Error: login response is undefined`);
          setErrorCaption(generateError('login.generalError'));
          setShowError(true);
        }
      }

      else {
        sessionStorage.setItem(SessionStorageKeys.ModulesAlreadyLoaded, 'true');
        const moduleManager = ModuleManager.getInstance();
        moduleManager.addModules().then(
          () => {
            eventBus.publish('ReadyForRendering', true);
            moduleManager.initiateThirdPartyLogin(updateUserName, updatePassword);

          })
      }
    };

    if (updateUserName.length > 0 && updatePassword.length > 0) {
      invokeLogin().then();
    } else {
      setShowError(false);
    }
  };

  /* istanbul ignore next */
  const onLanguageChanged = (
    language: string,
    userNameUpdated: string,
    passwordUpdated: string,
  ): void => {
    localStorage.setItem(localeKey, language);
    setUserName(userNameUpdated);
    setPassword(passwordUpdated);
    setLocale(language);
  };

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={I18n.getInstance().getMessages(locale)}
      textComponent="span"
    >
      <Loader
        isLoading={loading}
        text={<FormattedMessage id="login.loggingIn" />}
      >
        <LoginForm
          {...{
            userName,
            password,
            onSubmit,
            onLanguageChanged,
            showError,
            errorCaption,
            errorMessageSSL,
            setLoading,
            showGeneralError,
            setShowGeneralError,
            isAzureLogin
          }}
        />
      </Loader>
    </IntlProvider>
  );
};

export default LoginPage;
