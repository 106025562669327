import { BaseModule } from '../../BaseModule';
import { UtilitiesPlatform } from '../../../../common/utilities/Global';
import AppConfig from '../../../../configuration/AppConfig.json';
import { MessageTree, ModuleType } from 'web-modules-common';
import * as bluebird from "bluebird";
import { eventBus } from '../../../../common/services';
import log from 'loglevel';

export class ModuleFactory {
  public async loadModulesComponents(): Promise<Map<string, BaseModule>> {

    // eslint-disable-next-line no-async-promise-executor
    return new bluebird.Promise(async (resolve, reject) => {
      const moduleMap = this.generateModules();

      bluebird.each(Array.from(moduleMap).map(a => a[1]), async module => {
        if (this.shouldLoadModule(module)) await this.loadModuleComponent(module)
      }).then(() => {
        resolve(moduleMap);
      });

      return moduleMap;
    })
  }

  /* istanbul ignore next */
  public async loadModuleComponent(module: BaseModule): Promise<BaseModule> {
    // load Component async
    log.debug(`loading module: ${module.moduleID}`);
    let loadedModule = null;

    if (UtilitiesPlatform.getInstance().isDebugMode) {
      log.debug('debug mode')
    } else {
      switch (module.moduleID) {
        case 'Incident':
          loadedModule = await import('incident-module').then((m) => {
            log.debug(m);
            return m
          }).catch(error => {
            log.error(error);
          });

          break;

        case 'Map':
          loadedModule = await import('esri-maps-module').then((m) => {
            log.debug(m);
            return m
          }).catch(error => {
            log.error(error);
          });


          break;

        case 'Video':
          loadedModule = await import('video-module').then((m) => {
            log.debug(m);
            return m
          }).catch(error => {
            log.error(error);
          });

          break;

        case 'Dashboards':
          loadedModule = await import('dashboard-module').then((m) => {
            log.debug(m);
            return m;
          }).catch(error => {
            log.error(error);
          });

          break;

      }
    }


    if (loadedModule && !module.debug) {
      module.JSXComponent = loadedModule[module.componentName];
      module.Icon = loadedModule[module.iconName];
      module.serviceInstance = loadedModule[module.serviceName];
    }

      eventBus.publish(
        MessageTree.modules.action.addModule,
        module,
      );

    return module;
  }
  /* istanbul ignore end */

  public removeModuleComponent(
    moduleMap: Map<string, BaseModule>,
    moduleID: string,
  ): Map<string, BaseModule> {
    moduleMap.delete(moduleID);

    // fan out remove message using event tree
    eventBus.publish(
      MessageTree.modules.action.unLoadModule,
      moduleMap,
    );

    return moduleMap;
  }

  private shouldLoadModule(module :BaseModule): boolean {
    return module && module.componentName && module.componentName.length > 0 
  }


  private generateModules(): Map<string, BaseModule> {
    const modules = UtilitiesPlatform.getInstance().modulesFileAppMode(AppConfig.mode).modules;

    const jsonKeys = Object.keys(modules);
    let baseModule: BaseModule;
    const baseModuleMap: Map<string, BaseModule> = new Map<string, BaseModule>();

    for (let i = 0; i < jsonKeys.length; i++) {
      /* istanbul ignore next */
      if(!modules[jsonKeys[i]].visible) continue;

      baseModule = new BaseModule();
      baseModule.moduleID = modules[jsonKeys[i]].id;
      baseModule.moduleType = modules[jsonKeys[i]].type;
      baseModule.componentName = modules[jsonKeys[i]].component;
      baseModule.serviceName = modules[jsonKeys[i]].service;
      baseModule.config = modules[jsonKeys[i]].config;
      baseModule.certificate = modules[jsonKeys[i]].certificate;
      baseModule.endpoints = modules[jsonKeys[i]].endpoints;
      baseModule.name = modules[jsonKeys[i]].headerProperties.name;
      baseModule.iconName = modules[jsonKeys[i]].headerProperties.icon;
      baseModule.iconWidth = modules[jsonKeys[i]].headerProperties.iconWidth;
      baseModule.iconHeight = modules[jsonKeys[i]].headerProperties.iconHeight;  
      baseModule.notifications = modules[jsonKeys[i]].notifications;
      baseModuleMap.set(baseModule.moduleID, baseModule);
    }

    return baseModuleMap;
  }
}
