import React, { Component } from 'react';

interface IErrorBoundaryProps {}
interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    return this.state.hasError === false ? (
      <>{this.props.children}</>
    ) : (
      <h1>Something went wrong.</h1>
    );
  }
}
