import { UserLoginState } from './UserLogin.State';

export const UPDATE_USER_ID = 'UpdateUserID';
export const UPDATE_USER_NAME = 'UpdateUserName';
export const UPDATE_USER_ROLES = 'UpdateUserRoles';
export const UPDATE_USER_CONNECTED_STATUS =
  'UpdateUserConnectedStatus';

interface UpdateUserIDAction {
  type: typeof UPDATE_USER_ID | undefined;
  payload: UserLoginState;
}

interface UpdateUserNameAction {
  type: typeof UPDATE_USER_NAME | undefined;
  payload: UserLoginState;
}

interface UpdateUserRolesAction {
  type: typeof UPDATE_USER_ROLES | undefined;
  payload: UserLoginState;
}

interface UpdateUserConnectedStatusAction {
  type: typeof UPDATE_USER_CONNECTED_STATUS | undefined;
  payload: UserLoginState;
}

export type UserLoginTypes =
  | UpdateUserIDAction
  | UpdateUserNameAction
  | UpdateUserRolesAction
  | UpdateUserConnectedStatusAction;
