import { UserLoginState } from '../../types/UserLogin/UserLogin.State';
import {
  UPDATE_USER_ID,
  UPDATE_USER_NAME,
  UPDATE_USER_ROLES,
  UPDATE_USER_CONNECTED_STATUS,
  UserLoginTypes,
} from '../../types/UserLogin/UserLogin.Type';
import { UserLoginStatus } from 'web-modules-common';

const initialState: UserLoginState = {
  connected: UserLoginStatus.Connected,
  id: '',
  name: '',
  roles: [],
};

export function userLoginReducer(
  state = initialState,
  action: UserLoginTypes,
): UserLoginState {
  switch (action.type) {
    case UPDATE_USER_ID: {
      return {
        ...state,
        id: action.payload.id,
      };
    }

    case UPDATE_USER_NAME: {
      return {
        ...state,
        name: action.payload.name,
      };
    }

    case UPDATE_USER_ROLES: {
      return {
        ...state,
        roles: action.payload.roles,
      };
    }

    case UPDATE_USER_CONNECTED_STATUS: {
      return {
        ...state,
        connected: action.payload.connected,
      };
    }

    default:
      return state;
  }
}
