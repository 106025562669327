import React, { useState, useEffect } from 'react';
import AppConfig from '../../../../configuration/AppConfig.json';
import { UtilitiesPlatform } from "../../../utilities/Global";
import {
  StyledText,
  StyledSidebarItem,
  StyledSidebarWrapper,
  StyledIconWrapper,
} from './sidebarStyles';
import { useIntl } from 'react-intl';
import { BaseModule } from '../../../../modules/infrastructure/BaseModule';
import { ModuleManager } from '../../../../modules/infrastructure/ModuleManager';
import { SessionStorageKeys, I18n, MessageTree } from 'web-modules-common';
import {eventBus, moduleNavigationService} from '../../../services';

interface SidebarProps {
  
}

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const [selectedModuleId, setSelectedModuleId] = useState(
    sessionStorage.getItem(SessionStorageKeys.ActiveModuleId) ?
      sessionStorage.getItem(SessionStorageKeys.ActiveModuleId) :
      UtilitiesPlatform.getInstance().modulesFileAppMode(AppConfig.mode).defaultModuleId
  );
  const intl = useIntl();
  const locale = I18n.getInstance().getLocale();
  const isRTL = I18n.getInstance().isRTL(locale);
  const modules = ModuleManager.getInstance().getSidebarProperties();

  useEffect(() => {
    eventBus.subscribe(Sidebar.name, MessageTree.modules.action.globalActiveModule, onGlobalActivateModule);
   
    /* istanbul ignore next */
    return () => {
      eventBus.unsubscribe(Sidebar.name, MessageTree.modules.action.globalActiveModule);
    };
  }, [selectedModuleId]);

  //istanbul ignore next
  const onGlobalActivateModule = (args: any): void => {
    if (args.activeModuleId) {
      updateActiveModule(args.activeModuleId);
    }
  };

  const updateActiveModule = (moduleID: string): void => {
    if (selectedModuleId !== moduleID) { 
      eventBus.publish(MessageTree.modules.action.activeModule, moduleID);
      sessionStorage.setItem(SessionStorageKeys.ActiveModuleId, moduleID);
      setSelectedModuleId(moduleID);     
    }
  }

  const onModuleClick = (moduleID: string) => {
    updateActiveModule(moduleID);
  }

  const iconComponent = (Module: BaseModule): JSX.Element => {
    if (!Module.Icon) {
      return null;
    }

    return (
      <StyledIconWrapper 
        isRTL={isRTL} 
        iconWidth={Module.iconWidth ? Module.iconWidth : null}
        iconHeight={Module.iconHeight ? Module.iconHeight : null} >
        <Module.Icon />
      </StyledIconWrapper>
    );
  };

  const moduleButton = (Module: BaseModule) => {
    return (
      <StyledSidebarItem
        isRtl={isRTL}
        name={Module.moduleID}
        active={selectedModuleId === Module.moduleID}
        key={Module.moduleID}
        onClick={() => onModuleClick(Module.moduleID)}
      >
        {iconComponent(Module)}
        <StyledText>
          {intl.formatMessage({
            id: `sideBar.${Module.name}`,
          })}
        </StyledText>
      </StyledSidebarItem>
    );
  };

  return (
    <StyledSidebarWrapper id={`sideBar`}>
      {modules.map(module =>
        module.componentName.length > 0 ? moduleButton(module) : null,
      )}
    </StyledSidebarWrapper>
  );
};

export default Sidebar;
