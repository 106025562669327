import * as log from 'loglevel';
import {
  AccountInfo,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  serviceContainer,
  AuthenticationServiceBase,
} from 'web-modules-common';
import LoginConfigurationsAlternative from '../../../configuration/alternatives/Login';

const graph = require('@microsoft/microsoft-graph-client');

const PromptState = {
  LOGIN: 'login',
  SELECT_ACCOUNT: 'select_account',
  CONSENT: 'consent',
  NONE: 'none',
};

// @ts-ignore
const loginConfig = global.LoginConfigurations
  ? // @ts-ignore
    global.LoginConfigurations
  : LoginConfigurationsAlternative;
const azureConfig = loginConfig.Azure;

export class AzureService {
  private config;
  private userAgent: PublicClientApplication;
  private authenticationService = serviceContainer.get(
    AuthenticationServiceBase,
  ) as AuthenticationServiceBase;

  private loginState = {
    isAuthenticated: false,
    user: {},
    error: null,
  };

  constructor() {
    // 1. configure the idm server and cr - Dan Narkissi
    // 2. redirectUri on azure dashboard - you can talk with Matias Warth

    this.config = {
      clientID: azureConfig.clientID,
      tenantID: azureConfig.tenantID,
      redirectUri: azureConfig.redirectUri,
      authorityUrl: azureConfig.authorityUrl,
      scopes: [`api://${azureConfig.clientID}/user_impersonation`],
    };

    if (
      loginConfig.loginProvider === loginConfig.providers.AZURE.key &&
      azureConfig.clientID &&
      azureConfig.tenantID
    ) {
      this.userAgent = new PublicClientApplication({
        auth: {
          clientId: this.config.clientID,
          authority: `${this.config.authorityUrl}/${this.config.tenantID}`,
          redirectUri: this.config.redirectUri,
          postLogoutRedirectUri: window.location.origin,
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
        system: {
          loadFrameTimeout: 600000,
        },
      });
    }
  }

  /* istanbul ignore next */
  async login() {
    return new Promise((resolve, reject) => {
      this.userAgent
        .loginPopup({
          scopes: this.config.scopes,
          prompt: PromptState.LOGIN,
        })
        .then(async (response) => {
          const tokenInfo = await this.getTokenInfo(response.account);
          const data = await this.getTokenFromIdm(
            tokenInfo.accessToken,
          );
          if (data) {
            this.authenticationService.loginSuccessHandler(data);
            resolve(data);
          } else {
            reject();
          }
        })
        .catch((err) => {
          this.errorHandler(err);
          reject(err);
        });
    });
  }

  async getTokenFromIdm(token: string) {
    const response =
      await this.authenticationService.loginWithExternalToken(token);
    /* istanbul ignore next */
    if (!response.data && response.error)
      throw new Error(response.error as unknown as string);
    return response.data;
  }

  /* istanbul ignore next */
  async getTokenInfo(account: AccountInfo) {
    try {
      const accessToken = await this.userAgent.acquireTokenSilent({
        account: account,
        scopes: this.config.scopes,
      });

      return accessToken;
    } catch (err) {
      this.errorHandler(err);
      return null;
    }
  }

  /* istanbul ignore next */
  async getUserDetails(accessToken) {
    const client = this.getAuthenticatedClient(accessToken);

    const user = await client.api('/me').get();
    return user;
  }

  /* istanbul ignore next */
  getConfiguration() {
    return { ...this.config };
  }

  /* istanbul ignore next */
  logout() {
    this.userAgent.logoutRedirect();
  }

  /* istanbul ignore next */
  private errorHandler(err) {
    let error = {};
    if (typeof err === 'string') {
      const errParts = err.split('|');
      // eslint-disable-next-line no-const-assign
      error =
        errParts.length > 1
          ? { message: errParts[1], debug: errParts[0] }
          : { message: err };
    } else {
      error = {
        message: err.message,
        debug: JSON.stringify(err),
      };
    }

    log.error(error);

    this.loginState = {
      isAuthenticated: false,
      user: {},
      error: error,
    };
  }

  /* istanbul ignore next */
  private getAuthenticatedClient(accessToken) {
    // Initialize Graph client
    const client = graph.Client.init({
      // Use the provided access token to authenticate
      authProvider: (done) => {
        done(null, accessToken.accessToken);
      },
    });

    return client;
  }
}
