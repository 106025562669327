/* istanbul ignore file */
import { combineReducers, createStore } from 'redux';
import { userLoginReducer } from './reducers';

const allReducers = combineReducers({
  userLogin: userLoginReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }
  return allReducers(state, action);
};

export type AppState = ReturnType<typeof rootReducer>;

export function configureStore() {
  const store = createStore(rootReducer);

  return store;
}
