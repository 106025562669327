import * as log from 'loglevel';
import { hubConnection, signalR } from '../../../modules/@signalr-no-jquery/signalr-no-jquery';
import { Observable, Subject } from 'rxjs';
import { NotificationType, SessionStorageKeys, MessageTree, INotification, NotificationServiceBase, serviceContainer, EventBusBase } from 'web-modules-common';
import { filter } from 'rxjs/operators';
import { UtilitiesPlatform }  from '../../utilities/Global'
import AppConfig  from '../../../configuration/AppConfig.json';
import { reduce as _reduce, uniqBy as _uniqBy } from 'lodash';
import LoginConfigurationsAlternative from '../../../configuration/alternatives/Login';
import NotificationConfigurationAlternative from '../../../configuration/alternatives/Notification';

//@ts-ignore
 const maxNotificationsCount = global.NotificationConfiguration ? global.NotificationConfiguration.maxNotificationCount : NotificationConfigurationAlternative;

export class NotificationService extends NotificationServiceBase {
  private eventBus;
  private hubProxy;
  private loginConfig;
  private isTriggeredByReconnection: boolean;
  private shouldReconnect: boolean = true;
  private connectionId = null;
  private moduleState = {
    moduleSub$: new Subject<any>(),
    isModuleDataReloaded: false,
    waitingNotifications: [],
    API: {
      setIsModuleDataReloaded: (state) => {
        this.moduleState.isModuleDataReloaded = state;

        if (state && this.moduleState.waitingNotifications.length) {
          this.moduleState.waitingNotifications.forEach(element => {
            this.moduleState.moduleSub$.next(element);
          });
          this.moduleState.waitingNotifications = [];
        }
      },
    }
  }
  private trayNotificationSub$ = new Subject<any>();
  private selectedNotificationSub$ = new Subject<any>();
  private NotificationTrayStore: Array<INotification> = [];
  private ModulesGroupTopics: Array<any> = [];
  private TrayNotificationTypes: Array<NotificationType> = [];

  constructor() {
    super();
    this.eventBus = serviceContainer.get(EventBusBase) as EventBusBase;
    window.onbeforeunload = this.onClosingWindow.bind(this);
  }
  /* istanbul ignore next */
  get trayNotificationTypes() : Array<NotificationType>{
    return this.TrayNotificationTypes;
  }
  /* istanbul ignore next */
  set trayNotificationTypes(notificationTypes: Array<NotificationType>) {
    this.TrayNotificationTypes = notificationTypes;
  }
  /* istanbul ignore next */
  get notificationTrayStore(): Array<INotification> {
    return this.NotificationTrayStore;
  }

  private sortNotifications = (notificationsList: Array<INotification>): Array<INotification> => {
    if(!notificationsList || !notificationsList.length) return [];
    if(notificationsList.length === 1) return notificationsList;
    return notificationsList.sort((a,b) => {return new Date(b.notificationTime).getTime() - new Date(a.notificationTime).getTime()})
  }

  public setNotificationTrayStore(notifications: Array<INotification>, shouldTriggerEventBus): void{
    this.NotificationTrayStore = this.sortNotifications(_uniqBy(notifications,'id'));
    if(shouldTriggerEventBus){
      this.eventBus.publish(
        MessageTree.Notification.action.trayStoreUpdated,
        this.NotificationTrayStore
      );
    }
  }

  public removeNotificationFromTrayStore(notifications: Array<INotification>, shouldTriggerEventBus){
    if(!notifications || typeof notifications === 'undefined') {
      log.error('Error trying to remove notification from notification tray');
      return;
    }
    const currentTrayNotificationsStore = this.NotificationTrayStore;
    let newNotifications = [];
    if(notifications.length >= 0){
        notifications.forEach( n => {
          const notificationExists = currentTrayNotificationsStore && currentTrayNotificationsStore.length > 0 && currentTrayNotificationsStore.some( ctn => ctn.id === n.id);
          if(notificationExists){
            newNotifications = currentTrayNotificationsStore.filter( ctn => ctn.id !== n.id)
          }
        })
    }
    this.setNotificationTrayStore(newNotifications, shouldTriggerEventBus);
  }

   
  public addNotificationToTrayStore(notifications: Array<INotification>, shouldTriggerEventBus){
    const currentTrayNotificationsStore = this.NotificationTrayStore;
    if(notifications && notifications.length){
      const newNotifications = [
        ...notifications,
        ...currentTrayNotificationsStore,
      ].splice(0, maxNotificationsCount)
      this.setNotificationTrayStore(newNotifications, shouldTriggerEventBus);
    }
  }

  getModuleState() {
    return this.moduleState;
  }
/* istanbul ignore next */
  onClosingWindow() {
    this.disconnect(false);
    this.isTriggeredByReconnection = false;
    return null;
  }

  private collectModulesGroupTopics(): Array<any> {
    const modules = UtilitiesPlatform.getInstance().modulesFileAppMode(AppConfig.mode).modules;
    this.ModulesGroupTopics = _reduce( modules, (array, module) => {
      const shouldModuleSubScribe = module.visible && module.notifications && module.notifications.length > -1;
      if(shouldModuleSubScribe){
          module.notifications.forEach((mn) => {
              const topicExists = array.findIndex(m => m.topic === mn.topic);
              if(topicExists  > -1){
                  if(array[topicExists].isTrayNotification !== mn.isTrayNotification ){
                      array[topicExists].isTrayNotification = true;
                  } else {
                      array[topicExists] = mn.isTrayNotification
                  }
              } else {
                  array.push(mn)
              }
          })
      }
      return array;
    }, [] )
    return this.ModulesGroupTopics
  }

  async init() {
    //@ts-ignore
    this.loginConfig = global.LoginConfigurations? global.LoginConfigurations : LoginConfigurationsAlternative;
    this.configureHubProxy();
    this.isTriggeredByReconnection = false;
    this.collectModulesGroupTopics();
    return this.setConnectionEvents();
  }

/* istanbul ignore next */
  listenToGeneralNotifications(notificationType: NotificationType): Observable<any> {
    return this.moduleState.moduleSub$.pipe(filter( (data: any) => {
        const notificationTypes = data.NotificationType.split(', '); 
        return notificationTypes.includes(notificationType) 
      }
    ));
  }

  /* istanbul ignore next */
  listenToTrayNotifications(notificationType: NotificationType): Observable<any> {
    return this.trayNotificationSub$.pipe(filter( (data: any) => {
        const notificationTypes = data.NotificationType.split(', '); 
        return notificationTypes.includes(notificationType) 
      }
    ));
  }
/* istanbul ignore next */
  listenToSelectedNotification() : Observable<number>{
    return this.selectedNotificationSub$.asObservable()
  }

/* istanbul ignore next */
  updateSelectedNotification(notification: any){
    this.selectedNotificationSub$.next(notification);
  }

  disconnect(shouldReconnect = true): void {
    this.shouldReconnect = shouldReconnect;
    const {connected , reconnecting} = signalR['connectionState'];
    const state = this.hubProxy && this.hubProxy.connection && this.hubProxy.connection.state;
    if(this.hubProxy && this.hubProxy.connection && (state === connected || state === reconnecting)) {
      this.leaveGroups();
      this.hubProxy.connection.stop();
      this.onDisconnect();
    }
  }

  private onDisconnect(): void {
    if (this.hubProxy.connection.lastError) {
      log.info(
        `notificationService disconnected. Reason:  ${this.hubProxy.connection.lastError.message}`,
      );
    } else {
      log.info('notificationService disconnected');
    }
    if (this.shouldReconnect) {
      this.eventBus.publish(
        MessageTree.SignalR.action.signalRDisconnected,
        'SignalR_Disconnection'
      );
      this.onReconnect();
    }
  }

  private onReconnect(): void {
    log.info('notificationService reconnecting...');
    this.isTriggeredByReconnection = true;
    this.startConnection(true);
  }

  private configureHubProxy(): void {
    const currentUserId: number = parseInt(sessionStorage.getItem(SessionStorageKeys.CurrentUserID), 10);
    const connection = hubConnection(
      `${this.loginConfig.endPoints.WebApi.ServerUrl}/signalr`,
      {
        useDefaultPath: false,
      },
    );
    this.hubProxy = connection.createHubProxy('notificationHub');
    if(typeof currentUserId !== 'undefined' && !Number.isNaN(currentUserId)) this.hubProxy.connection.qs = `userid=${currentUserId}`;
    this.hubProxy.connection.logging = true;
    this.hubProxy.connection.transportConnectTimeout = this.loginConfig.signalR.transportConnectTimeout;
    /* istanbul ignore next */  
    this.hubProxy.on('notify', message => {
      log.info('notificationService message: ', message);
      this.handleNotification(message);
    });
  }

  handleNotification(notification: any): void {
    if (notification) {
      try {
        const {data} = notification;
        const notifications = data.NotificationType.split(', ');
        
          const intersection = [...new Set(this.TrayNotificationTypes.filter(element => notifications.includes(element)))];
          const isTrayNotificationType = intersection&&intersection.length;
         
          if(isTrayNotificationType){
            this.trayNotificationSub$.next(data)
          }
          if (this.moduleState.isModuleDataReloaded) {
              this.moduleState.moduleSub$.next(data);
          } else {
            this.moduleState.waitingNotifications.push(data)
          }
      } catch (error) {
        /* istanbul ignore next */
        log.error(error);
      }
    }
  }
   /* istanbul ignore next */
  private async startConnection(isReconnection? : boolean): Promise<void> {
    await this.hubProxy.connection
      .start()
      .done(() => {
        if(this.connectionId) {
          this.leaveGroups(this.connectionId);
        }
        
        this.connectionId = this.hubProxy.connection.id;
        log.info(
          `notificationService Now connected, connection ID=
            ${this.hubProxy.connection.id}: ${new Date}`,
        );
        this.joinGroups();
        if(isReconnection && this.isTriggeredByReconnection){
          log.info('Notification Service: initializing reconnection');
          this.eventBus.publish(
            MessageTree.SignalR.action.signalRReconnected,
            'SignalR_Reconnection'
          );
          this.isTriggeredByReconnection = false;
        }
      })
      .fail((error: string) => {
        const errorMessage = `notificationService Failed: ${JSON.stringify(error)}`;
        log.error(errorMessage);
        if(isReconnection && this.isTriggeredByReconnection){
          setTimeout(() => {
            this.onDisconnect();
          },5000)
        }
      });
  }
  /* istanbul ignore next */
  private async setConnectionEvents() {
    return new Promise<void>((res, rej) => {
      this.startConnection().then(() => res());

      this.hubProxy.connection.error(error => {
        this.eventBus.publish(
          MessageTree.SignalR.action.signalRDisconnected,
          'SignalR_Disconnection'
        );
        const errorMessage = `notificationService error: ${JSON.stringify(error)}`;
        log.error(errorMessage);
      });

      this.hubProxy.connection.reconnected(() => {
        this.eventBus.publish(
          MessageTree.SignalR.action.signalRReconnected,
          'SignalR_Reconnection'
        );
        log.info('notificationService reconnected');
        this.joinGroups();
      });

      this.hubProxy.connection.connectionSlow (() => {
        log.info('notificationService connection is slow');
      });

      this.hubProxy.connection.disconnected(() => {
        setTimeout(() => {
          this.onDisconnect();
        }, 5000);
      });
    })
  }
/* istanbul ignore next */
  private joinGroups(): void {
    try {
      this.ModulesGroupTopics.forEach((tg) => {
        try {
          this.hubProxy.invoke('joinGroup', tg.topic);
        } catch (error) {
          log.error(error)
        }
      })
      
    } catch (error) {
      log.error(error)
    }
  }
/* istanbul ignore next */
  private leaveGroups(connectionId?: string): void {
    try {
      this.ModulesGroupTopics.forEach((tg) => {
        if(tg.isTrayNotification !== true ){
          try {
            if(connectionId) {
              this.hubProxy.invoke('leaveGroup', tg.topic, connectionId);
            }
            else {
              this.hubProxy.invoke('leaveGroup', tg.topic);   
            }
          } catch (error) {
            log.error(error)
          }
        }
      })

      this.connectionId = null;
    } catch (error) {
      log.error(error)
    }

  }
/* istanbul ignore next */
  public leaveGroupsExternal(groupTopics: Array<string>): void{
    if(!groupTopics || !groupTopics.length) return;
    groupTopics.forEach((topic) => {
      const topicCanBeUnsubsucribed = this.ModulesGroupTopics.find( gt => gt.topic === topic);
      if(topicCanBeUnsubsucribed && topicCanBeUnsubsucribed.isTrayNotification !== true){
        try {
          this.hubProxy.invoke('leaveGroup', topic);
        } catch (error) {
          /* istanbul ignore next */
          log.error(error)
        }
      } else {
        log.warn(`cannot unsubscribe topic ${topic}. topic does not exist or is being used elsewhere in the system`)
      }
    })
  }


}
