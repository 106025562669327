import React, { FC, Suspense, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import AppConfig from '../../../../configuration/AppConfig.json';
import { UtilitiesPlatform } from '../../../utilities/Global';
import { BaseModule } from '../../../../modules/infrastructure/BaseModule';
import { eventBus, moduleNavigationService } from '../../../services';
import { SessionStorageKeys, MessageTree, ModuleType, IncidentModuleArgs } from 'web-modules-common';

export interface IWorkingAreaProps {
  modules?: Map<string, BaseModule>;
}

const WorkingArea: FC<IWorkingAreaProps> = ({ modules }) => {
  const intl = useIntl();
  const {name} = WorkingArea;
  const [selectedNotificationData, setSelectedNotificationData] = useState(null)
  const [activeModuleId, setActiveModuleId] = useState(
    sessionStorage.getItem(SessionStorageKeys.ActiveModuleId)? 
    sessionStorage.getItem(SessionStorageKeys.ActiveModuleId) : 
    UtilitiesPlatform.getInstance().modulesFileAppMode(AppConfig.mode).defaultModuleId
  );

  useEffect(() => {
    eventBus.subscribe(name, MessageTree.modules.action.activeModule, onActivateModule);
    eventBus.subscribe(name, MessageTree.Notification.action.notificationSelected,onNotificationSelected);
   
    /* istanbul ignore next */
    return () => {
      eventBus.unsubscribe(name, MessageTree.modules.action.activeModule);
      eventBus.unsubscribe(name, MessageTree.Notification.action.notificationSelected);
    };
  }, [activeModuleId]);


  const noModules = intl.formatMessage({ id: 'noModules' });
  const noModulesTemplate = (
    <div>
      <h1>{noModules}</h1>
    </div>
  );

  const moduleNotFound = intl.formatMessage({ id: 'moduleNotFound' });
  const moduleNotFoundTemplate = (
    <div>
      <h1>{moduleNotFound}</h1>
    </div>
  );

  const moduleComponent = (Module: BaseModule): JSX.Element => {
    if(!Module.JSXComponent) {
      return moduleNotFoundTemplate;
    }
    
    const moduleNotificationTopics = modules.get(activeModuleId).notifications || [];
    return (
      <Module.JSXComponent
        key={`module_${Module.moduleID}`}
        moduleProps={{ moduleID: Module.moduleID, selectedNotificationData, moduleNotificationTopics, intlProvider:intl }}
      />
    );
  };

  const onNotificationSelected = (args: any): void => {
    /* istanbul ignore next */
    if(!args || !args.id) return;
    const {notificationData} = args;
    setSelectedNotificationData(args);
    if(notificationData && notificationData.IncidentId && (activeModuleId !== 'Incident')) { 
      setActiveModuleId('Incident');
      moduleNavigationService.navigateTo(ModuleType.Incidents, new IncidentModuleArgs(notificationData.IncidentId));
    }else{
      args.onSelect && args.onSelect();
    }
  };

  const onActivateModule = (moduleIdToActive: string): void => {
    if(activeModuleId !== moduleIdToActive){
      setActiveModuleId(moduleIdToActive);
    }
  };

  const renderModule = (id: string) => {
    if (modules.size > 0) {
      if (id && modules.get(id) !== undefined) {
        return moduleComponent(modules.get(id));
      } else {
        return moduleNotFoundTemplate;
      }
    }

    return noModulesTemplate
  }

  return (
    <Suspense fallback={noModulesTemplate}>
      {renderModule(activeModuleId)}
    </Suspense>
  );
};

export default WorkingArea;
